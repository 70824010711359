
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { TextField, Checkbox } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment';
import AdventureTourDeclaration from 'assets/declarationfiles/adventuretourdeclaration.pdf';
import TrainerDeclaration from 'assets/declarationfiles/adventuretourdeclarationfortrainers.pdf';
import * as service from '../../services/service';

function AdventureTourRegistration() {
    const [state, setState] = useState(
        {
            emailid: '', mobileno: '', applicantname: '', name: '', authorizedperson: '', applicantaddress: '', coaddress: '',
            whatsappno: '', applicantwebsite: '', socialmediahandle: '', otheractivity: '', documentverifiedstatus: '', beachname: '',
            activitylocationaddress: ''
        });

    const [regionId, setRegionId] = useState('');
    const [regionData, setRegionData] = useState([]);
    const [districtId, setDistrictId] = useState('');
    const [districtData, setDistrictData] = useState([]);
    const [applicantType, setApplicantType] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [operator, setOperator] = useState(false);
    const [aggregator, setAggregator] = useState(false);
    const [camp, setCamp] = useState(false);
    const [trainingInstitute, setTrainingInstitute] = useState(false);
    const [association, setAssociation] = useState(false);
    const [indivisualOraganizer, setIndivisualOraganizer] = useState(false);
    const [activityCategory, setActivityCategory] = useState('');
    const [naukavihar, setNaukaVihar] = useState('');
    const [activityData, setActivityData] = useState([]);
    // const [activity, setActivity] = useState('');

    const [equipments, setEquipments] = useState([
        { adventureactivity: { label: "", value: "" }, equipname: '', unit: '', manufacturer: '', purchasedate: new Date() }
    ])

    const [adharFile, setAdharFile] = useState('');
    const [panFile, setPanFile] = useState('');
    const [addressProofFile, setAddressProofFile] = useState('');
    const [challanFile, setChallanFile] = useState('');
    const [organizationProofFile, setOrganizationProofFile] = useState('');
    const [incomeTaxFile, setIncomeTaxFile] = useState('');
    const [gstCertificateFile, setGSTCertificateFile] = useState('');
    const [declarationFile, setDeclarationFile] = useState('');
    const [boatRegistrationFile, setBoatRegistrationFile] = useState('');
    const [PCCPoliceClearanceCertFile, setPCCPoliceClearanceCertFile] = useState('');
    const [renewalFeeChallanFile, setRenewalFeeChallanFile] = useState('');
    const [UDYAMRegistrationCertFile, setUDYAMRegistrationCertFile] = useState('');
    const [firstAidCertFile, setFirstAidCertFile] = useState('');
    const [landFirstAidCertFile, setLandFirstAidCertFile] = useState('');
    const [landTrainerFirstAidDeclarationFile, setLandTrainerFirstAidDeclarationFile] = useState('');
    const [waterTrainerFirstAidDeclarationFile, setWaterTrainerFirstAidDeclarationFile] = useState('');
    const [airTrainerFirstAidDeclarationFile, setAirTrainerFirstAidDeclarationFile] = useState('');

    const applicantTypeData = [{ label: "Person", value: "Person" }, { label: "Sole Proprietorship", value: "Sole Proprietorship" },
    { label: "Company", value: "Company" }, { label: "Partnership Business", value: "Partnership Business" },
    { label: "LLP (Limited Liability Partnership)", value: "LLP (Limited Liability Partnership)" },
    { label: "Trust", value: "Trust" }, { label: "Co-operative Society", value: "Co-operative Society" }];

    const activityCategoryData = [{ label: "Land Activities", value: "Land Activities" },
    { label: "Water Activities", value: "Water Activities" }, { label: "Air Activities", value: "Air Activities" }];

    const naukaData = [{ label: "Nauka Vihar", value: "Nauka Vihar" }, { label: "Jal Krida", value: "Jal Krida" },
    { label: "River Cruz", value: "River Cruz" }, { label: "House Boat", value: "House Boat" }];

    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const navigate = useNavigate();

    const getRegions = (id) => {
        fetch(
            service.GET_REGION_BY_STATE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    StateId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.regionId, label: item.region }));
                setRegionData(data2);
            });
    };

    const getDistricts = (id) => {
        fetch(
            service.GET_DISTRICT_BY_REGION_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    RegionId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));
                setDistrictData(data2);
            });
    };

    useEffect(() => {
        setLoading(true);
        getRegions(1);
        if (window.sessionStorage.getItem('userData') != null) {
            setState({ ...state, emailid: JSON.parse(window.sessionStorage.getItem('userData')).emailId });
        }
        setLoading(false);
    }, []);

    function getExtension(filename) {
        return filename.split('.').pop()
    }

    const validate = () => {
        let isValid = true;
        // const maxSize = 150; // in KB
        const maxSize = 5120; // in KB = 5MB
        // const maxSize = 1024; // in KB = 1MB
        const errs = {};
        if (!state.emailid) {
            isValid = false;
            errs[".emailid"] = "Please enter Email Id";
        }
        if (!state.mobileno) {
            isValid = false;
            errs[".mobileno"] = "Please enter Mobile No.";
        }
        if (!state.applicantname) {
            isValid = false;
            errs[".applicantname"] = "Please enter Applicant Name";
        }
        if (!state.name) {
            isValid = false;
            errs[".name"] = "Please enter Name";
        }
        if (!applicantType) {
            isValid = false;
            errs[".applicantType"] = "Please select Applicant Type";
        }
        if (!operator && !aggregator && !camp && !trainingInstitute && !association && !indivisualOraganizer) {
            isValid = false;
            errs[".categoryregistration"] = "Please select at least 1 category";
        }

        if (!state.authorizedperson) {
            isValid = false;
            errs[".authorizedperson"] = "Please enter";
        }
        if (!startDate) {
            isValid = false;
            errs[".startDate"] = "Please enter";
        }
        if (!state.applicantaddress) {
            isValid = false;
            errs[".applicantaddress"] = "Please enter";
        }
        if (!regionId) {
            isValid = false;
            errs[".region"] = "Please select Region";
        }
        if (!districtId) {
            isValid = false;
            errs[".district"] = "Please select District";
        }
        if (!state.whatsappno) {
            isValid = false;
            errs[".whatsappno"] = "Please enter";
        }
        if (!activityCategory) {
            isValid = false;
            errs[".activityCategory"] = "Please select";
        }
        if (!state.beachname) {
            isValid = false;
            errs[".beachname"] = "Please enter";
        }
        // if (!activity) {
        //     isValid = false;
        //     errs[".activity"] = "Please select";
        // }        
        if (equipments.length === 0 || equipments[0].equipname === ''
            || equipments[0].manufacturer === '' || equipments[0].adventureactivity.value === "") {
            isValid = false;
            errs[".equipment"] = "Please enter at least 1 equipment";
        }
        if (!panFile) {
            isValid = false;
            errs[".panFile"] = "Please upload file";
        }
        if (!adharFile) {
            isValid = false;
            errs[".adharFile"] = "Please upload file";
        }
        if (!addressProofFile) {
            isValid = false;
            errs[".addressProofFile"] = "Please upload file";
        }
        if (!challanFile) {
            isValid = false;
            errs[".challanFile"] = "Please upload file";
        }
        // if (!organizationProofFile) {
        //     isValid = false;
        //     errs[".organizationProofFile"] = "Please upload file";
        // }
        // if (!incomeTaxFile) {
        //     isValid = false;
        //     errs[".incomeTaxFile"] = "Please upload file";
        // }
        // if (!gstCertificateFile) {
        //     isValid = false;
        //     errs[".gstCertificateFile"] = "Please upload file";
        // }
        if (!declarationFile) {
            isValid = false;
            errs[".declarationFile"] = "Please upload file";
        }
        if (panFile && getExtension(panFile.name).toLowerCase() !== 'pdf'
            && getExtension(panFile.name).toLowerCase() !== 'jpg'
            && getExtension(panFile.name).toLowerCase() !== 'jpeg'
            && getExtension(panFile.name).toLowerCase() !== 'png'
            && getExtension(panFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".panFile"] = "Please upload pdf/jpg/jpeg/png/docx  file";
        }
        if (adharFile && getExtension(adharFile.name).toLowerCase() !== 'pdf'
            && getExtension(adharFile.name).toLowerCase() !== 'jpg'
            && getExtension(adharFile.name).toLowerCase() !== 'jpeg'
            && getExtension(adharFile.name).toLowerCase() !== 'png'
            && getExtension(adharFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".adharFile"] = "Please upload pdf/jpg/jpeg/png/docx  file";
        }
        if (addressProofFile && getExtension(addressProofFile.name).toLowerCase() !== 'pdf'
            && getExtension(addressProofFile.name).toLowerCase() !== 'jpg'
            && getExtension(addressProofFile.name).toLowerCase() !== 'jpeg'
            && getExtension(addressProofFile.name).toLowerCase() !== 'png'
            && getExtension(addressProofFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".addressProofFile"] = "Please upload pdf/jpg/jpeg/png/docx  file";
        }
        if (challanFile && getExtension(challanFile.name).toLowerCase() !== 'pdf'
            && getExtension(challanFile.name).toLowerCase() !== 'jpg'
            && getExtension(challanFile.name).toLowerCase() !== 'jpeg'
            && getExtension(challanFile.name).toLowerCase() !== 'png'
            && getExtension(challanFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".challanFile"] = "Please upload pdf/jpg/jpeg/png/docx  file";
        }
        if (organizationProofFile && getExtension(organizationProofFile.name).toLowerCase() !== 'pdf'
            && getExtension(organizationProofFile.name).toLowerCase() !== 'jpg'
            && getExtension(organizationProofFile.name).toLowerCase() !== 'jpeg'
            && getExtension(organizationProofFile.name).toLowerCase() !== 'png'
            && getExtension(organizationProofFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".organizationProofFile"] = "Please upload pdf/jpg/jpeg/png/docx  file";
        }
        if (incomeTaxFile && getExtension(incomeTaxFile.name).toLowerCase() !== 'pdf'
            && getExtension(incomeTaxFile.name).toLowerCase() !== 'jpg'
            && getExtension(incomeTaxFile.name).toLowerCase() !== 'jpeg'
            && getExtension(incomeTaxFile.name).toLowerCase() !== 'png'
            && getExtension(incomeTaxFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".incomeTaxFile"] = "Please upload pdf/jpg/jpeg/png/docx  file";
        }
        if (gstCertificateFile && getExtension(gstCertificateFile.name).toLowerCase() !== 'pdf'
            && getExtension(gstCertificateFile.name).toLowerCase() !== 'jpg'
            && getExtension(gstCertificateFile.name).toLowerCase() !== 'jpeg'
            && getExtension(gstCertificateFile.name).toLowerCase() !== 'png'
            && getExtension(gstCertificateFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".gstCertificateFile"] = "Please upload pdf/jpg/jpeg/png/docx  file";
        }
        if (declarationFile && getExtension(declarationFile.name).toLowerCase() !== 'pdf'
            && getExtension(declarationFile.name).toLowerCase() !== 'jpg'
            && getExtension(declarationFile.name).toLowerCase() !== 'jpeg'
            && getExtension(declarationFile.name).toLowerCase() !== 'png'
            && getExtension(declarationFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".declarationFile"] = "Please upload pdf/jpg/jpeg/png/docx  file";
        }

        if (panFile && panFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".panFile"] = "File size limit 5 MB";
        }
        if (adharFile && adharFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".adharFile"] = "File size limit 5 MB";
        }
        if (addressProofFile && addressProofFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".addressProofFile"] = "File size limit 5 MB";
        }
        if (challanFile && challanFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".challanFile"] = "File size limit 5 MB";
        }
        if (organizationProofFile && organizationProofFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".organizationProofFile"] = "File size limit 5 MB";
        }
        if (incomeTaxFile && incomeTaxFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".incomeTaxFile"] = "File size limit 5 MB";
        }
        if (gstCertificateFile && gstCertificateFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".gstCertificateFile"] = "File size limit 5 MB";
        }
        if (declarationFile && declarationFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".declarationFile"] = "File size limit 5 MB";
        }
        // if (declarationForTrainersFile && declarationForTrainersFile.size / 1024 > maxSize) {
        //     isValid = false;
        //     errs[".declarationForTrainersFile"] = "File size limit 5 MB";
        // }

        if (activityCategory.value === 'Water Activities') {
            if (!boatRegistrationFile) {
                isValid = false;
                errs[".boatRegistrationFile"] = "Please upload file";
            }
            // if (!thirdPartyInsuranceFile) {
            //     isValid = false;
            //     errs[".thirdPartyInsuranceFile"] = "Please upload file";
            // }
            if (!PCCPoliceClearanceCertFile) {
                isValid = false;
                errs[".PCCPoliceClearanceCertFile"] = "Please upload file";
            }
            if (!renewalFeeChallanFile) {
                isValid = false;
                errs[".renewalFeeChallanFile"] = "Please upload file";
            }
            if (!waterTrainerFirstAidDeclarationFile) {
                isValid = false;
                errs[".waterTrainerFirstAidDeclarationFile"] = "Please upload file";
            }
            if (waterTrainerFirstAidDeclarationFile && getExtension(waterTrainerFirstAidDeclarationFile.name).toLowerCase() !== 'pdf'
                && getExtension(waterTrainerFirstAidDeclarationFile.name).toLowerCase() !== 'jpg'
                && getExtension(waterTrainerFirstAidDeclarationFile.name).toLowerCase() !== 'jpeg'
                && getExtension(waterTrainerFirstAidDeclarationFile.name).toLowerCase() !== 'png'
                && getExtension(waterTrainerFirstAidDeclarationFile.name).toLowerCase() !== 'docx') {
                isValid = false;
                errs['.waterTrainerFirstAidDeclarationFile'] = "Please upload pdf/jpg/jpeg/png/docx  file";
            }
            if (waterTrainerFirstAidDeclarationFile && waterTrainerFirstAidDeclarationFile.size / 1024 > maxSize) {
                isValid = false;
                errs['.waterTrainerFirstAidDeclarationFile'] = "File size limit 5 MB";
            }

            if (boatRegistrationFile && getExtension(boatRegistrationFile.name).toLowerCase() !== 'pdf'
                && getExtension(boatRegistrationFile.name).toLowerCase() !== 'jpg'
                && getExtension(boatRegistrationFile.name).toLowerCase() !== 'jpeg'
                && getExtension(boatRegistrationFile.name).toLowerCase() !== 'png'
                && getExtension(boatRegistrationFile.name).toLowerCase() !== 'docx') {
                isValid = false;
                errs[".boatRegistrationFile"] = "Please upload pdf/jpg/jpeg/png/docx  file";
            }
            // if (thirdPartyInsuranceFile && getExtension(thirdPartyInsuranceFile.name).toLowerCase() !== 'pdf'
            //     && getExtension(thirdPartyInsuranceFile.name).toLowerCase() !== 'jpg'
            //     && getExtension(thirdPartyInsuranceFile.name).toLowerCase() !== 'jpeg'
            //     && getExtension(thirdPartyInsuranceFile.name).toLowerCase() !== 'png'
            //     && getExtension(thirdPartyInsuranceFile.name).toLowerCase() !== 'docx') {
            //     isValid = false;
            //     errs[".thirdPartyInsuranceFile"] = "Please upload pdf/jpg/jpeg/png/docx  file";
            // }
            if (PCCPoliceClearanceCertFile && getExtension(PCCPoliceClearanceCertFile.name).toLowerCase() !== 'pdf'
                && getExtension(PCCPoliceClearanceCertFile.name).toLowerCase() !== 'jpg'
                && getExtension(PCCPoliceClearanceCertFile.name).toLowerCase() !== 'jpeg'
                && getExtension(PCCPoliceClearanceCertFile.name).toLowerCase() !== 'png'
                && getExtension(PCCPoliceClearanceCertFile.name).toLowerCase() !== 'docx') {
                isValid = false;
                errs[".PCCPoliceClearanceCertFile"] = "Please upload pdf/jpg/jpeg/png/docx  file";
            }
            if (renewalFeeChallanFile && getExtension(renewalFeeChallanFile.name).toLowerCase() !== 'pdf'
                && getExtension(renewalFeeChallanFile.name).toLowerCase() !== 'jpg'
                && getExtension(renewalFeeChallanFile.name).toLowerCase() !== 'jpeg'
                && getExtension(renewalFeeChallanFile.name).toLowerCase() !== 'png'
                && getExtension(renewalFeeChallanFile.name).toLowerCase() !== 'docx') {
                isValid = false;
                errs[".renewalFeeChallanFile"] = "Please upload pdf/jpg/jpeg/png/docx  file";
            }
            if (boatRegistrationFile && boatRegistrationFile.size / 1024 > maxSize) {
                isValid = false;
                errs[".boatRegistrationFile"] = "File size limit 5 MB";
            }
            // if (thirdPartyInsuranceFile && thirdPartyInsuranceFile.size / 1024 > maxSize) {
            //     isValid = false;
            //     errs[".thirdPartyInsuranceFile"] = "File size limit 5 MB";
            // }
            if (PCCPoliceClearanceCertFile && PCCPoliceClearanceCertFile.size / 1024 > maxSize) {
                isValid = false;
                errs[".PCCPoliceClearanceCertFile"] = "File size limit 5 MB";
            }
            if (renewalFeeChallanFile && renewalFeeChallanFile.size / 1024 > maxSize) {
                isValid = false;
                errs[".renewalFeeChallanFile"] = "File size limit 5 MB";
            }


            if (equipments.length > 0) {
                equipments.forEach((element, index) => {
                    if (element.adventureactivity.value === 'Banana Boat' || element.adventureactivity.value === 'Bumper Boat'
                        || element.adventureactivity.value === 'Sofa Boat' || element.adventureactivity.value === 'Pedal Boat') {
                        if (!element.fitnessCertFile) {
                            isValid = false;
                            errs[`.fitnessCertFile${index}`] = "Please upload file";
                        }
                        if (!element.boatPhotoFile) {
                            isValid = false;
                            errs[`.boatPhotoFile${index}`] = "Please upload file";
                        }
                        if (element.fitnessCertFile && getExtension(element.fitnessCertFile.name).toLowerCase() !== 'pdf'
                            && getExtension(element.fitnessCertFile.name).toLowerCase() !== 'jpg'
                            && getExtension(element.fitnessCertFile.name).toLowerCase() !== 'jpeg'
                            && getExtension(element.fitnessCertFile.name).toLowerCase() !== 'png'
                            && getExtension(element.fitnessCertFile.name).toLowerCase() !== 'docx') {
                            isValid = false;
                            errs[`.fitnessCertFile${index}`] = "Please upload pdf/jpg/jpeg/png/docx  file";
                        }
                        if (element.boatPhotoFile && getExtension(element.boatPhotoFile.name).toLowerCase() !== 'pdf'
                            && getExtension(element.boatPhotoFile.name).toLowerCase() !== 'jpg'
                            && getExtension(element.boatPhotoFile.name).toLowerCase() !== 'jpeg'
                            && getExtension(element.boatPhotoFile.name).toLowerCase() !== 'png'
                            && getExtension(element.boatPhotoFile.name).toLowerCase() !== 'docx') {
                            isValid = false;
                            errs[`.boatPhotoFile${index}`] = "Please upload pdf/jpg/jpeg/png/docx  file";
                        }
                        if (element.fitnessCertFile && element.fitnessCertFile.size / 1024 > maxSize) {
                            isValid = false;
                            errs[`.fitnessCertFile${index}`] = "File size limit 5 MB";
                        }
                        if (element.boatPhotoFile && element.boatPhotoFile.size / 1024 > maxSize) {
                            isValid = false;
                            errs[`.boatPhotoFile${index}`] = "File size limit 5 MB";
                        }
                    }
                    else if (element.adventureactivity.value === 'Jet Ski' || element.adventureactivity.value === 'Parasailing'
                        || element.adventureactivity.value === 'Speed Boat'
                        || element.adventureactivity.value === 'River Cruz'
                        || element.adventureactivity.value === 'House Boat') {
                        if (!element.surveyCerificateFile) {
                            isValid = false;
                            errs[`.surveyCerificateFile${index}`] = "Please upload file";
                        }
                        if (!element.vrcCerificateFile) {
                            isValid = false;
                            errs[`.vrcCerificateFile${index}`] = "Please upload file";
                        }
                        if (!element.boatInsuranceFile) {
                            isValid = false;
                            errs[`.boatInsuranceFile${index}`] = "Please upload file";
                        }
                        if (!element.boatPhotoFile) {
                            isValid = false;
                            errs[`.boatPhotoFile${index}`] = "Please upload file";
                        }
                        if (!element.powerBoatHandlingCertFile) {
                            isValid = false;
                            errs[`.powerBoatHandlingCertFile${index}`] = "Please upload file";
                        }
                        if (!element.lifeSavingCertFile) {
                            isValid = false;
                            errs[`.lifeSavingCertFile${index}`] = "Please upload file";
                        }
                        if (!element.thirdPartyInsuranceFile) {
                            isValid = false;
                            errs[`.thirdPartyInsuranceFile${index}`] = "Please upload file";
                        }
                        if (element.surveyCerificateFile && getExtension(element.surveyCerificateFile.name).toLowerCase() !== 'pdf'
                            && getExtension(element.surveyCerificateFile.name).toLowerCase() !== 'jpg'
                            && getExtension(element.surveyCerificateFile.name).toLowerCase() !== 'jpeg'
                            && getExtension(element.surveyCerificateFile.name).toLowerCase() !== 'png'
                            && getExtension(element.surveyCerificateFile.name).toLowerCase() !== 'docx') {
                            isValid = false;
                            errs[`.surveyCerificateFile${index}`] = "Please upload pdf/jpg/jpeg/png/docx  file";
                        }
                        if (element.vrcCerificateFile && getExtension(element.vrcCerificateFile.name).toLowerCase() !== 'pdf'
                            && getExtension(element.vrcCerificateFile.name).toLowerCase() !== 'jpg'
                            && getExtension(element.vrcCerificateFile.name).toLowerCase() !== 'jpeg'
                            && getExtension(element.vrcCerificateFile.name).toLowerCase() !== 'png'
                            && getExtension(element.vrcCerificateFile.name).toLowerCase() !== 'docx') {
                            isValid = false;
                            errs[`.vrcCerificateFile${index}`] = "Please upload pdf/jpg/jpeg/png/docx  file";
                        }
                        if (element.boatInsuranceFile && getExtension(element.boatInsuranceFile.name).toLowerCase() !== 'pdf'
                            && getExtension(element.boatInsuranceFile.name).toLowerCase() !== 'jpg'
                            && getExtension(element.boatInsuranceFile.name).toLowerCase() !== 'jpeg'
                            && getExtension(element.boatInsuranceFile.name).toLowerCase() !== 'png'
                            && getExtension(element.boatInsuranceFile.name).toLowerCase() !== 'docx') {
                            isValid = false;
                            errs[`.boatInsuranceFile${index}`] = "Please upload pdf/jpg/jpeg/png/docx  file";
                        }
                        if (element.boatPhotoFile && getExtension(element.boatPhotoFile.name).toLowerCase() !== 'pdf'
                            && getExtension(element.boatPhotoFile.name).toLowerCase() !== 'jpg'
                            && getExtension(element.boatPhotoFile.name).toLowerCase() !== 'jpeg'
                            && getExtension(element.boatPhotoFile.name).toLowerCase() !== 'png'
                            && getExtension(element.boatPhotoFile.name).toLowerCase() !== 'docx') {
                            isValid = false;
                            errs[`.boatPhotoFile${index}`] = "Please upload pdf/jpg/jpeg/png/docx  file";
                        }
                        if (element.powerBoatHandlingCertFile && getExtension(element.powerBoatHandlingCertFile.name).toLowerCase() !== 'pdf'
                            && getExtension(element.powerBoatHandlingCertFile.name).toLowerCase() !== 'jpg'
                            && getExtension(element.powerBoatHandlingCertFile.name).toLowerCase() !== 'jpeg'
                            && getExtension(element.powerBoatHandlingCertFile.name).toLowerCase() !== 'png'
                            && getExtension(element.powerBoatHandlingCertFile.name).toLowerCase() !== 'docx') {
                            isValid = false;
                            errs[`.powerBoatHandlingCertFile${index}`] = "Please upload pdf/jpg/jpeg/png/docx  file";
                        }
                        if (element.lifeSavingCertFile && getExtension(element.lifeSavingCertFile.name).toLowerCase() !== 'pdf'
                            && getExtension(element.lifeSavingCertFile.name).toLowerCase() !== 'jpg'
                            && getExtension(element.lifeSavingCertFile.name).toLowerCase() !== 'jpeg'
                            && getExtension(element.lifeSavingCertFile.name).toLowerCase() !== 'png'
                            && getExtension(element.lifeSavingCertFile.name).toLowerCase() !== 'docx') {
                            isValid = false;
                            errs[`.lifeSavingCertFile${index}`] = "Please upload pdf/jpg/jpeg/png/docx  file";
                        }
                        if (element.thirdPartyInsuranceFile && getExtension(element.thirdPartyInsuranceFile.name).toLowerCase() !== 'pdf'
                            && getExtension(element.thirdPartyInsuranceFile.name).toLowerCase() !== 'jpg'
                            && getExtension(element.thirdPartyInsuranceFile.name).toLowerCase() !== 'jpeg'
                            && getExtension(element.thirdPartyInsuranceFile.name).toLowerCase() !== 'png'
                            && getExtension(element.thirdPartyInsuranceFile.name).toLowerCase() !== 'docx') {
                            isValid = false;
                            errs[`.thirdPartyInsuranceFile${index}`] = "Please upload pdf/jpg/jpeg/png/docx  file";
                        }
                        if (element.surveyCerificateFile && element.surveyCerificateFile.size / 1024 > maxSize) {
                            isValid = false;
                            errs[`.surveyCerificateFile${index}`] = "File size limit 5 MB";
                        }
                        if (element.vrcCerificateFile && element.vrcCerificateFile.size / 1024 > maxSize) {
                            isValid = false;
                            errs[`.vrcCerificateFile${index}`] = "File size limit 5 MB";
                        }
                        if (element.boatInsuranceFile && element.boatInsuranceFile.size / 1024 > maxSize) {
                            isValid = false;
                            errs[`.boatInsuranceFile${index}`] = "File size limit 5 MB";
                        }
                        if (element.boatPhotoFile && element.boatPhotoFile.size / 1024 > maxSize) {
                            isValid = false;
                            errs[`.boatPhotoFile${index}`] = "File size limit 5 MB";
                        }
                        if (element.powerBoatHandlingCertFile && element.powerBoatHandlingCertFile.size / 1024 > maxSize) {
                            isValid = false;
                            errs[`.powerBoatHandlingCertFile${index}`] = "File size limit 5 MB";
                        }
                        if (element.lifeSavingCertFile && element.lifeSavingCertFile.size / 1024 > maxSize) {
                            isValid = false;
                            errs[`.lifeSavingCertFile${index}`] = "File size limit 5 MB";
                        }
                        if (element.thirdPartyInsuranceFile && element.thirdPartyInsuranceFile.size / 1024 > maxSize) {
                            isValid = false;
                            errs[`.thirdPartyInsuranceFile${index}`] = "File size limit 5 MB";
                        }
                    }
                });
            }
        }
        else if (activityCategory.value === 'Land Activities') {
            if (equipments.length > 0) {
                equipments.forEach((element, index) => {
                    if (element.adventureactivity.value === 'All Terrian Vehicle (ATV)') {
                        if (!element.atvFile) {
                            isValid = false;
                            errs[`.atvFile${index}`] = "Please upload file";
                        }
                        if (!element.atvPurchaseFile) {
                            isValid = false;
                            errs[`.atvPurchaseFile${index}`] = "Please upload file";
                        }
                        if (!element.licenceFile) {
                            isValid = false;
                            errs[`.licenceFile${index}`] = "Please upload file";
                        }
                        if (element.atvFile && getExtension(element.atvFile.name).toLowerCase() !== 'pdf'
                            && getExtension(element.atvFile.name).toLowerCase() !== 'jpg'
                            && getExtension(element.atvFile.name).toLowerCase() !== 'jpeg'
                            && getExtension(element.atvFile.name).toLowerCase() !== 'png'
                            && getExtension(element.atvFile.name).toLowerCase() !== 'docx') {
                            isValid = false;
                            errs[`.atvFile${index}`] = "Please upload pdf/jpg/jpeg/png/docx  file";
                        }
                        if (element.atvPurchaseFile && getExtension(element.atvPurchaseFile.name).toLowerCase() !== 'pdf'
                            && getExtension(element.atvPurchaseFile.name).toLowerCase() !== 'jpg'
                            && getExtension(element.atvPurchaseFile.name).toLowerCase() !== 'jpeg'
                            && getExtension(element.atvPurchaseFile.name).toLowerCase() !== 'png'
                            && getExtension(element.atvPurchaseFile.name).toLowerCase() !== 'docx') {
                            isValid = false;
                            errs[`.atvPurchaseFile${index}`] = "Please upload pdf/jpg/jpeg/png/docx  file";
                        }
                        if (element.licenceFile && getExtension(element.licenceFile.name).toLowerCase() !== 'pdf'
                            && getExtension(element.licenceFile.name).toLowerCase() !== 'jpg'
                            && getExtension(element.licenceFile.name).toLowerCase() !== 'jpeg'
                            && getExtension(element.licenceFile.name).toLowerCase() !== 'png'
                            && getExtension(element.licenceFile.name).toLowerCase() !== 'docx') {
                            isValid = false;
                            errs[`.licenceFile${index}`] = "Please upload pdf/jpg/jpeg/png/docx  file";
                        }
                        if (element.atvFile && element.atvFile.size / 1024 > maxSize) {
                            isValid = false;
                            errs[`.atvFile${index}`] = "File size limit 5 MB";
                        }
                        if (element.atvPurchaseFile && element.atvPurchaseFile.size / 1024 > maxSize) {
                            isValid = false;
                            errs[`.atvPurchaseFile${index}`] = "File size limit 5 MB";
                        }
                        if (element.licenceFile && element.licenceFile.size / 1024 > maxSize) {
                            isValid = false;
                            errs[`.licenceFile${index}`] = "File size limit 5 MB";
                        }
                    }
                    else if (element.adventureactivity.value !== 'All Terrian Vehicle (ATV)' && applicantType.value !== 'Person') {
                        if (!element.companyRegistrationCertFile) {
                            isValid = false;
                            errs[`.companyRegistrationCertFile${index}`] = "Please upload file";
                        }
                        // if (!element.skilledCertFile) {
                        //     isValid = false;
                        //     errs[`.skilledCertFile${index}`] = "Please upload file";
                        // }
                        // if (!element.insuranceCertFile) {
                        //     isValid = false;
                        //     errs[`.insuranceCertFile${index}`] = "Please upload file";
                        // }
                        // if (!element.physicalFitCertFile) {
                        //     isValid = false;
                        //     errs[`.physicalFitCertFile${index}`] = "Please upload file";
                        // }
                        // if (!element.landCharacterCertFile) {
                        //     isValid = false;
                        //     errs[`.landCharacterCertFile${index}`] = "Please upload file";
                        // }
                        if (element.companyRegistrationCertFile && getExtension(element.companyRegistrationCertFile.name).toLowerCase() !== 'pdf'
                            && getExtension(element.companyRegistrationCertFile.name).toLowerCase() !== 'jpg'
                            && getExtension(element.companyRegistrationCertFile.name).toLowerCase() !== 'jpeg'
                            && getExtension(element.companyRegistrationCertFile.name).toLowerCase() !== 'png'
                            && getExtension(element.companyRegistrationCertFile.name).toLowerCase() !== 'docx') {
                            isValid = false;
                            errs[`.companyRegistrationCertFile${index}`] = "Please upload pdf/jpg/jpeg/png/docx  file";
                        }
                        if (element.skilledCertFile && getExtension(element.skilledCertFile.name).toLowerCase() !== 'pdf'
                            && getExtension(element.skilledCertFile.name).toLowerCase() !== 'jpg'
                            && getExtension(element.skilledCertFile.name).toLowerCase() !== 'jpeg'
                            && getExtension(element.skilledCertFile.name).toLowerCase() !== 'png'
                            && getExtension(element.skilledCertFile.name).toLowerCase() !== 'docx') {
                            isValid = false;
                            errs[`.skilledCertFile${index}`] = "Please upload pdf/jpg/jpeg/png/docx  file";
                        }
                        if (element.insuranceCertFile && getExtension(element.insuranceCertFile.name).toLowerCase() !== 'pdf'
                            && getExtension(element.insuranceCertFile.name).toLowerCase() !== 'jpg'
                            && getExtension(element.insuranceCertFile.name).toLowerCase() !== 'jpeg'
                            && getExtension(element.insuranceCertFile.name).toLowerCase() !== 'png'
                            && getExtension(element.insuranceCertFile.name).toLowerCase() !== 'docx') {
                            isValid = false;
                            errs[`.insuranceCertFile${index}`] = "Please upload pdf/jpg/jpeg/png/docx  file";
                        }
                        if (element.physicalFitCertFile && getExtension(element.physicalFitCertFile.name).toLowerCase() !== 'pdf'
                            && getExtension(element.physicalFitCertFile.name).toLowerCase() !== 'jpg'
                            && getExtension(element.physicalFitCertFile.name).toLowerCase() !== 'jpeg'
                            && getExtension(element.physicalFitCertFile.name).toLowerCase() !== 'png'
                            && getExtension(element.physicalFitCertFile.name).toLowerCase() !== 'docx') {
                            isValid = false;
                            errs[`.physicalFitCertFile${index}`] = "Please upload pdf/jpg/jpeg/png/docx  file";
                        }
                        if (element.landCharacterCertFile && getExtension(element.landCharacterCertFile.name).toLowerCase() !== 'pdf'
                            && getExtension(element.landCharacterCertFile.name).toLowerCase() !== 'jpg'
                            && getExtension(element.landCharacterCertFile.name).toLowerCase() !== 'jpeg'
                            && getExtension(element.landCharacterCertFile.name).toLowerCase() !== 'png'
                            && getExtension(element.landCharacterCertFile.name).toLowerCase() !== 'docx') {
                            isValid = false;
                            errs[`.landCharacterCertFile${index}`] = "Please upload pdf/jpg/jpeg/png/docx  file";
                        }
                        if (element.companyRegistrationCertFile && element.companyRegistrationCertFile.size / 1024 > maxSize) {
                            isValid = false;
                            errs[`.companyRegistrationCertFile${index}`] = "File size limit 5 MB";
                        }
                        if (element.skilledCertFile && element.skilledCertFile.size / 1024 > maxSize) {
                            isValid = false;
                            errs[`.skilledCertFile${index}`] = "File size limit 5 MB";
                        }
                        if (element.insuranceCertFile && element.insuranceCertFile.size / 1024 > maxSize) {
                            isValid = false;
                            errs[`.insuranceCertFile${index}`] = "File size limit 5 MB";
                        }
                        if (element.physicalFitCertFile && element.physicalFitCertFile.size / 1024 > maxSize) {
                            isValid = false;
                            errs[`.physicalFitCertFile${index}`] = "File size limit 5 MB";
                        }
                        if (element.landCharacterCertFile && element.landCharacterCertFile.size / 1024 > maxSize) {
                            isValid = false;
                            errs[`.landCharacterCertFile${index}`] = "File size limit 5 MB";
                        }
                    }
                });
            }

            if (landFirstAidCertFile && getExtension(landFirstAidCertFile.name).toLowerCase() !== 'pdf'
                && getExtension(landFirstAidCertFile.name).toLowerCase() !== 'jpg'
                && getExtension(landFirstAidCertFile.name).toLowerCase() !== 'jpeg'
                && getExtension(landFirstAidCertFile.name).toLowerCase() !== 'png'
                && getExtension(landFirstAidCertFile.name).toLowerCase() !== 'docx') {
                isValid = false;
                errs[".landFirstAidCertFile"] = "Please upload pdf/jpg/jpeg/png/docx  file";
            }
            if (landTrainerFirstAidDeclarationFile && getExtension(landTrainerFirstAidDeclarationFile.name).toLowerCase() !== 'pdf'
                && getExtension(landTrainerFirstAidDeclarationFile.name).toLowerCase() !== 'jpg'
                && getExtension(landTrainerFirstAidDeclarationFile.name).toLowerCase() !== 'jpeg'
                && getExtension(landTrainerFirstAidDeclarationFile.name).toLowerCase() !== 'png'
                && getExtension(landTrainerFirstAidDeclarationFile.name).toLowerCase() !== 'docx') {
                isValid = false;
                errs[".landTrainerFirstAidDeclarationFile"] = "Please upload pdf/jpg/jpeg/png/docx  file";
            }
            if (landFirstAidCertFile && landFirstAidCertFile.size / 1024 > maxSize) {
                isValid = false;
                errs[".landFirstAidCertFile"] = "File size limit 5 MB";
            }
            if (landTrainerFirstAidDeclarationFile && landTrainerFirstAidDeclarationFile.size / 1024 > maxSize) {
                isValid = false;
                errs[".landTrainerFirstAidDeclarationFile"] = "File size limit 5 MB";
            }
        }
        else if (activityCategory.value === 'Air Activities') {
            if (equipments.length > 0) {
                equipments.forEach((element, index) => {
                    // if (!element.paraglidingSkilledFile) {
                    //     isValid = false;
                    //     errs[`.paraglidingSkilledFile${index}`] = "Please upload file";
                    // }
                    // if (!element.pilotInsuranceCertFile) {
                    //     isValid = false;
                    //     errs[`.pilotInsuranceCertFile${index}`] = "Please upload file";
                    // }
                    // if (!element.pilotCharacterCertFile) {
                    //     isValid = false;
                    //     errs[`.pilotCharacterCertFile${index}`] = "Please upload file";
                    // }
                    // if (!element.pilotMedicalExamCertFile) {
                    //     isValid = false;
                    //     errs[`.pilotMedicalExamCertFile${index}`] = "Please upload file";
                    // }
                    // if (!element.rentalAgreementFile) {
                    //     isValid = false;
                    //     errs[`.rentalAgreementFile${index}`] = "Please upload file";
                    // }
                    // if (!element.satBaraCertFile) {
                    //     isValid = false;
                    //     errs[`.satBaraCertFile${index}`] = "Please upload file";
                    // }
                    // if (!element.indemnityFormFile) {
                    //     isValid = false;
                    //     errs[`.indemnityFormFile${index}`] = "Please upload file";
                    // }
                    if (element.paraglidingSkilledFile && getExtension(element.paraglidingSkilledFile.name).toLowerCase() !== 'pdf'
                        && getExtension(element.paraglidingSkilledFile.name).toLowerCase() !== 'jpg'
                        && getExtension(element.paraglidingSkilledFile.name).toLowerCase() !== 'jpeg'
                        && getExtension(element.paraglidingSkilledFile.name).toLowerCase() !== 'png'
                        && getExtension(element.paraglidingSkilledFile.name).toLowerCase() !== 'docx') {
                        isValid = false;
                        errs[`.paraglidingSkilledFile${index}`] = "Please upload pdf/jpg/jpeg/png/docx  file";
                    }
                    if (element.pilotInsuranceCertFile && getExtension(element.pilotInsuranceCertFile.name).toLowerCase() !== 'pdf'
                        && getExtension(element.pilotInsuranceCertFile.name).toLowerCase() !== 'jpg'
                        && getExtension(element.pilotInsuranceCertFile.name).toLowerCase() !== 'jpeg'
                        && getExtension(element.pilotInsuranceCertFile.name).toLowerCase() !== 'png'
                        && getExtension(element.pilotInsuranceCertFile.name).toLowerCase() !== 'docx') {
                        isValid = false;
                        errs[`.pilotInsuranceCertFile${index}`] = "Please upload pdf/jpg/jpeg/png/docx  file";
                    }
                    if (element.pilotCharacterCertFile && getExtension(element.pilotCharacterCertFile.name).toLowerCase() !== 'pdf'
                        && getExtension(element.pilotCharacterCertFile.name).toLowerCase() !== 'jpg'
                        && getExtension(element.pilotCharacterCertFile.name).toLowerCase() !== 'jpeg'
                        && getExtension(element.pilotCharacterCertFile.name).toLowerCase() !== 'png'
                        && getExtension(element.pilotCharacterCertFile.name).toLowerCase() !== 'docx') {
                        isValid = false;
                        errs[`.pilotCharacterCertFile${index}`] = "Please upload pdf/jpg/jpeg/png/docx  file";
                    }
                    if (element.pilotMedicalExamCertFile && getExtension(element.pilotMedicalExamCertFile.name).toLowerCase() !== 'pdf'
                        && getExtension(element.pilotMedicalExamCertFile.name).toLowerCase() !== 'jpg'
                        && getExtension(element.pilotMedicalExamCertFile.name).toLowerCase() !== 'jpeg'
                        && getExtension(element.pilotMedicalExamCertFile.name).toLowerCase() !== 'png'
                        && getExtension(element.pilotMedicalExamCertFile.name).toLowerCase() !== 'docx') {
                        isValid = false;
                        errs[`.pilotMedicalExamCertFile${index}`] = "Please upload pdf/jpg/jpeg/png/docx  file";
                    }
                    if (element.rentalAgreementFile && getExtension(element.rentalAgreementFile.name).toLowerCase() !== 'pdf'
                        && getExtension(element.rentalAgreementFile.name).toLowerCase() !== 'jpg'
                        && getExtension(element.rentalAgreementFile.name).toLowerCase() !== 'jpeg'
                        && getExtension(element.rentalAgreementFile.name).toLowerCase() !== 'png'
                        && getExtension(element.rentalAgreementFile.name).toLowerCase() !== 'docx') {
                        isValid = false;
                        errs[`.rentalAgreementFile${index}`] = "Please upload pdf/jpg/jpeg/png/docx  file";
                    }
                    if (element.satBaraCertFile && getExtension(element.satBaraCertFile.name).toLowerCase() !== 'pdf'
                        && getExtension(element.satBaraCertFile.name).toLowerCase() !== 'jpg'
                        && getExtension(element.satBaraCertFile.name).toLowerCase() !== 'jpeg'
                        && getExtension(element.satBaraCertFile.name).toLowerCase() !== 'png'
                        && getExtension(element.satBaraCertFile.name).toLowerCase() !== 'docx') {
                        isValid = false;
                        errs[`.satBaraCertFile${index}`] = "Please upload pdf/jpg/jpeg/png/docx  file";
                    }
                    if (element.indemnityFormFile && getExtension(element.indemnityFormFile.name).toLowerCase() !== 'pdf'
                        && getExtension(element.indemnityFormFile.name).toLowerCase() !== 'jpg'
                        && getExtension(element.indemnityFormFile.name).toLowerCase() !== 'jpeg'
                        && getExtension(element.indemnityFormFile.name).toLowerCase() !== 'png'
                        && getExtension(element.indemnityFormFile.name).toLowerCase() !== 'docx') {
                        isValid = false;
                        errs[`.indemnityFormFile${index}`] = "Please upload pdf/jpg/jpeg/png/docx  file";
                    }
                    if (element.paraglidingSkilledFile && element.paraglidingSkilledFile.size / 1024 > maxSize) {
                        isValid = false;
                        errs[`.paraglidingSkilledFile${index}`] = "File size limit 5 MB";
                    }
                    if (element.pilotInsuranceCertFile && element.pilotInsuranceCertFile.size / 1024 > maxSize) {
                        isValid = false;
                        errs[`.pilotInsuranceCertFile${index}`] = "File size limit 5 MB";
                    }
                    if (element.pilotCharacterCertFile && element.pilotCharacterCertFile.size / 1024 > maxSize) {
                        isValid = false;
                        errs[`.pilotCharacterCertFile${index}`] = "File size limit 5 MB";
                    }
                    if (element.pilotMedicalExamCertFile && element.pilotMedicalExamCertFile.size / 1024 > maxSize) {
                        isValid = false;
                        errs[`.pilotMedicalExamCertFile${index}`] = "File size limit 5 MB";
                    }
                    if (element.rentalAgreementFile && element.rentalAgreementFile.size / 1024 > maxSize) {
                        isValid = false;
                        errs[`.rentalAgreementFile${index}`] = "File size limit 5 MB";
                    }
                    if (element.satBaraCertFile && element.satBaraCertFile.size / 1024 > maxSize) {
                        isValid = false;
                        errs[`.satBaraCertFile${index}`] = "File size limit 5 MB";
                    }
                    if (element.indemnityFormFile && element.indemnityFormFile.size / 1024 > maxSize) {
                        isValid = false;
                        errs[`.indemnityFormFile${index}`] = "File size limit 5 MB";
                    }
                });
            }

            if (!airTrainerFirstAidDeclarationFile) {
                isValid = false;
                errs[".airTrainerFirstAidDeclarationFile"] = "Please upload file";
            }
            if (airTrainerFirstAidDeclarationFile && getExtension(airTrainerFirstAidDeclarationFile.name).toLowerCase() !== 'pdf'
                && getExtension(airTrainerFirstAidDeclarationFile.name).toLowerCase() !== 'jpg'
                && getExtension(airTrainerFirstAidDeclarationFile.name).toLowerCase() !== 'jpeg'
                && getExtension(airTrainerFirstAidDeclarationFile.name).toLowerCase() !== 'png'
                && getExtension(airTrainerFirstAidDeclarationFile.name).toLowerCase() !== 'docx') {
                isValid = false;
                errs['.airTrainerFirstAidDeclarationFile'] = "Please upload pdf/jpg/jpeg/png/docx  file";
            }
            if (airTrainerFirstAidDeclarationFile && airTrainerFirstAidDeclarationFile.size / 1024 > maxSize) {
                isValid = false;
                errs['.airTrainerFirstAidDeclarationFile'] = "File size limit 5 MB";
            }

            if (UDYAMRegistrationCertFile && getExtension(UDYAMRegistrationCertFile.name).toLowerCase() !== 'pdf'
                && getExtension(UDYAMRegistrationCertFile.name).toLowerCase() !== 'jpg'
                && getExtension(UDYAMRegistrationCertFile.name).toLowerCase() !== 'jpeg'
                && getExtension(UDYAMRegistrationCertFile.name).toLowerCase() !== 'png'
                && getExtension(UDYAMRegistrationCertFile.name).toLowerCase() !== 'docx') {
                isValid = false;
                errs[".UDYAMRegistrationCertFile"] = "Please upload pdf/jpg/jpeg/png/docx  file";
            }

            if (firstAidCertFile && getExtension(firstAidCertFile.name).toLowerCase() !== 'pdf'
                && getExtension(firstAidCertFile.name).toLowerCase() !== 'jpg'
                && getExtension(firstAidCertFile.name).toLowerCase() !== 'jpeg'
                && getExtension(firstAidCertFile.name).toLowerCase() !== 'png'
                && getExtension(firstAidCertFile.name).toLowerCase() !== 'docx') {
                isValid = false;
                errs[".firstAidCertFile"] = "Please upload pdf/jpg/jpeg/png/docx  file";
            }

            if (UDYAMRegistrationCertFile && UDYAMRegistrationCertFile.size / 1024 > maxSize) {
                isValid = false;
                errs[".UDYAMRegistrationCertFile"] = "File size limit 5 MB";
            }

            if (firstAidCertFile && firstAidCertFile.size / 1024 > maxSize) {
                isValid = false;
                errs[".firstAidCertFile"] = "File size limit 5 MB";
            }
        }
        setErrors(errs);
        return isValid;
    }

    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const regionChangeHandler = (ev) => {
        setRegionId(ev);
        setDistrictId(null);
        getDistricts(ev.value);
    }
    const districtChangeHandler = (ev) => {
        setDistrictId(ev);
    }
    const applicantTypeChangeHandler = (ev) => {
        setApplicantType(ev);
    }

    const handleOperatorCheckboxChange = (checked) => {
        setOperator(checked);
    }
    const handleAggregatorCheckboxChange = (checked) => {
        setAggregator(checked);
    }
    const handleCampCheckboxChange = (checked) => {
        setCamp(checked);
    }
    const handleTrainingInstituteCheckboxChange = (checked) => {
        setTrainingInstitute(checked);
    }
    const handleAssociationCheckboxChange = (checked) => {
        setAssociation(checked);
    }
    const handleIndividualOrganizerCheckboxChange = (checked) => {
        setIndivisualOraganizer(checked);
    }
    const activityCategoryChangeHandler = (ev) => {
        setActivityCategory(ev);
        if (ev.value === 'Land Activities') {
            const data = [{ label: "Trekking/Rappelling/Nature Trail/Temporary Camping/Rock Climbing", value: "Trekking/Rappelling/Nature Trail/Temporary Camping/Rock Climbing" },
            { label: "Pinnacle Climbing", value: "Pinnacle Climbing" }, { label: "Valley Crossing/River Crossing", value: "Valley Crossing/River Crossing" },
            { label: "Caving", value: "Caving" }, { label: "Zipline", value: "Zipline" }, { label: "Rope Course", value: "Rope Course" }, { label: "Bike Tour", value: "Bike Tour" },
            { label: "Cycling", value: "Cycling" }, { label: "All Terrian Vehicle (ATV)", value: "All Terrian Vehicle (ATV)" }];
            setActivityData(data);
        }
        else if (ev.value === 'Water Activities') {
            const data = [
                { label: "Banana Boat", value: "Banana Boat" },
                { label: "Bumper Boat", value: "Bumper Boat" },
                { label: "Sofa Boat", value: "Sofa Boat" },
                { label: "Jet Ski", value: "Jet Ski" },
                { label: "Parasailing", value: "Parasailing" },
                { label: "Sea Water Rafting", value: "Sea Water Rafting" },
                { label: "Kite Surfing", value: "Kite Surfing" },
                { label: "Canoeing", value: "Canoeing" },
                { label: "Water Scooter", value: "Water Scooter" },
                { label: "Speed Boat", value: "Speed Boat" },
                { label: "Kayaking", value: "Kayaking" },
                { label: "Paddle Surfing", value: "Paddle Surfing" },
                { label: "Power Boat", value: "Power Boat" },
                { label: "River Rafting", value: "River Rafting" },
                { label: "Snorkeling", value: "Snorkeling" },
                { label: "Scuba Diving", value: "Scuba Diving" },
                { label: "River Cruz", value: "River Cruz" },
                { label: "House Boat", value: "House Boat" },
                { label: "Pedal Boat", value: "Pedal Boat" }];
            setActivityData(data);
        }
        else if (ev.value === 'Air Activities') {
            const data = [{ label: "Sky Diving", value: "Sky Diving" },
            { label: "Paragliding", value: "Paragliding" }, { label: "Hot Air Balloons", value: "Hot Air Balloons" },
            { label: "Paramotoring", value: "Paramotoring" }, { label: "Air Safari", value: "Air Safari" }];
            setActivityData(data);
        }

        const newfield = { adventureactivity: { label: "", value: "" }, equipname: '', unit: '', manufacturer: '', purchasedate: new Date() }
        setEquipments([newfield]);
    }
    const naukaChangeHandler = (ev) => {
        setNaukaVihar(ev);
    }

    const handleFormChange = (index, event, type) => {
        // const maxSize = 150; // in KB       
        const maxSize = 5120; // in KB = 5MB
        const data = [...equipments];
        if (type === 'date') {
            data[index].purchasedate = new Date(event);
        }
        else if (type === 'activy') {
            data[index].adventureactivity = event;
            setEquipments(data);

            if (activityCategory.value === 'Land Activities') {
                if (event.value === 'All Terrian Vehicle (ATV)') {
                    const newfield = {
                        adventureactivity: { label: "", value: "" }, equipname: '', unit: '', manufacturer: '', purchasedate: new Date(),
                        atvFile: '', atvPurchaseFile: '', licenceFile: ''
                    }
                    setEquipments([...equipments, newfield])
                }
                else {
                    const newfield = {
                        adventureactivity: { label: "", value: "" }, equipname: '', unit: '', manufacturer: '', purchasedate: new Date(),
                        companyRegistrationCertFile: '', skilledCertFile: '',
                        insuranceCertFile: '', physicalFitCertFile: '', landCharacterCertFile: ''
                    }
                    setEquipments([...equipments, newfield])
                }
            }

            if (activityCategory.value === 'Air Activities') {
                const newfield = {
                    adventureactivity: { label: "", value: "" }, equipname: '', unit: '', manufacturer: '', purchasedate: new Date(),
                    paraglidingSkilledFile: '', pilotInsuranceCertFile: '', pilotCharacterCertFile: '', pilotMedicalExamCertFile: '',
                    rentalAgreementFile: '', satBaraCertFile: '',
                    indemnityFormFile: ''
                }
                setEquipments([...equipments, newfield])
            }

            if (activityCategory.value === 'Water Activities') {
                if (event.value === 'Banana Boat' || event.value === 'Bumper Boat' || event.value === 'Sofa Boat' || event.value === 'Pedal Boat') {
                    const newfield = {
                        adventureactivity: { label: "", value: "" }, equipname: '', unit: '', manufacturer: '', purchasedate: new Date(),
                        fitnessCertFile: '', boatPhotoFile: ''
                    }
                    setEquipments([...equipments, newfield])
                }
                else if (event.value === 'Jet Ski' || event.value === 'Parasailing' || event.value === 'Speed Boat'
                    || event.value === 'River Cruz' || event.value === 'House Boat') {
                    const newfield = {
                        adventureactivity: { label: "", value: "" }, equipname: '', unit: '', manufacturer: '', purchasedate: new Date(),
                        surveyCerificateFile: '', vrcCerificateFile: '', boatInsuranceFile: '', boatPhotoFile: '',
                        powerBoatHandlingCertFile: '', lifeSavingCertFile: '', thirdPartyInsuranceFile: ''
                    }
                    setEquipments([...equipments, newfield])
                }
                else {
                    const newfield = {
                        adventureactivity: { label: "", value: "" }, equipname: '', unit: '', manufacturer: '', purchasedate: new Date()
                    }
                    setEquipments([...equipments, newfield])
                }
            }
        }
        else if (type === 'equipname' || type === 'manufacturer') {
            data[index][event.target.name] = event.target.value;
        }

        if (activityCategory.value === 'Water Activities') {
            if (data[index].adventureactivity.value === 'Banana Boat' || data[index].adventureactivity.value === 'Bumper Boat'
                || data[index].adventureactivity.value === 'Sofa Boat' || data[index].adventureactivity.value === 'Pedal Boat') {
                if (type === 'fitnessCertFile') {
                    if (event.size / 1024 > maxSize) {
                        // eslint-disable-next-line
                        alert("File size limit 5 MB");
                    }
                    data[index].fitnessCertFile = event;
                }
                else if (type === 'boatPhotoFile') {
                    if (event.size / 1024 > maxSize) {
                        // eslint-disable-next-line
                        alert("File size limit 5 MB");
                    }
                    data[index].boatPhotoFile = event;
                }
            }
            else if (data[index].adventureactivity.value === 'Jet Ski' || data[index].adventureactivity.value === 'Parasailing'
                || data[index].adventureactivity.value === 'Speed Boat'
                || data[index].adventureactivity.value === 'River Cruz'
                || data[index].adventureactivity.value === 'House Boat') {
                if (type === 'surveyCerificateFile') {
                    if (event.size / 1024 > maxSize) {
                        // eslint-disable-next-line
                        alert("File size limit 5 MB");
                    }
                    data[index].surveyCerificateFile = event;
                }
                else if (type === 'vrcCerificateFile') {
                    if (event.size / 1024 > maxSize) {
                        // eslint-disable-next-line
                        alert("File size limit 5 MB");
                    }
                    data[index].vrcCerificateFile = event;
                }
                else if (type === 'boatInsuranceFile') {
                    if (event.size / 1024 > maxSize) {
                        // eslint-disable-next-line
                        alert("File size limit 5 MB");
                    }
                    data[index].boatInsuranceFile = event;
                }
                else if (type === 'boatPhotoFile') {
                    if (event.size / 1024 > maxSize) {
                        // eslint-disable-next-line
                        alert("File size limit 5 MB");
                    }
                    data[index].boatPhotoFile = event;
                }
                else if (type === 'powerBoatHandlingCertFile') {
                    if (event.size / 1024 > maxSize) {
                        // eslint-disable-next-line
                        alert("File size limit 5 MB");
                    }
                    data[index].powerBoatHandlingCertFile = event;
                }
                else if (type === 'lifeSavingCertFile') {
                    if (event.size / 1024 > maxSize) {
                        // eslint-disable-next-line
                        alert("File size limit 5 MB");
                    }
                    data[index].lifeSavingCertFile = event;
                }
                else if (type === 'thirdPartyInsuranceFile') {
                    if (event.size / 1024 > maxSize) {
                        // eslint-disable-next-line
                        alert("File size limit 5 MB");
                    }
                    data[index].thirdPartyInsuranceFile = event;
                }
            }
        }
        else if (activityCategory.value === 'Land Activities') {
            if (data[index].adventureactivity.value === 'All Terrian Vehicle (ATV)') {
                if (type === 'atvFile') {
                    if (event.size / 1024 > maxSize) {
                        // eslint-disable-next-line
                        alert("File size limit 5 MB");
                    }
                    data[index].atvFile = event;
                }
                else if (type === 'atvPurchaseFile') {
                    if (event.size / 1024 > maxSize) {
                        // eslint-disable-next-line
                        alert("File size limit 5 MB");
                    }
                    data[index].atvPurchaseFile = event;
                }
                else if (type === 'licenceFile') {
                    if (event.size / 1024 > maxSize) {
                        // eslint-disable-next-line
                        alert("File size limit 5 MB");
                    }
                    data[index].licenceFile = event;
                }
            }
            else if (type === 'companyRegistrationCertFile') {
                if (event.size / 1024 > maxSize) {
                    // eslint-disable-next-line
                    alert("File size limit 5 MB");
                }
                data[index].companyRegistrationCertFile = event;
            }
            else if (type === 'skilledCertFile') {
                if (event.size / 1024 > maxSize) {
                    // eslint-disable-next-line
                    alert("File size limit 5 MB");
                }
                data[index].skilledCertFile = event;
            }
            else if (type === 'insuranceCertFile') {
                if (event.size / 1024 > maxSize) {
                    // eslint-disable-next-line
                    alert("File size limit 5 MB");
                }
                data[index].insuranceCertFile = event;
            }
            else if (type === 'physicalFitCertFile') {
                if (event.size / 1024 > maxSize) {
                    // eslint-disable-next-line
                    alert("File size limit 5 MB");
                }
                data[index].physicalFitCertFile = event;
            }
            else if (type === 'landCharacterCertFile') {
                if (event.size / 1024 > maxSize) {
                    // eslint-disable-next-line
                    alert("File size limit 5 MB");
                }
                data[index].landCharacterCertFile = event;
            }
        }
        else if (activityCategory.value === 'Air Activities') {
            if (type === 'paraglidingSkilledFile') {
                if (event.size / 1024 > maxSize) {
                    // eslint-disable-next-line
                    alert("File size limit 5 MB");
                }
                data[index].paraglidingSkilledFile = event;
            }
            else if (type === 'pilotInsuranceCertFile') {
                if (event.size / 1024 > maxSize) {
                    // eslint-disable-next-line
                    alert("File size limit 5 MB");
                }
                data[index].pilotInsuranceCertFile = event;
            }
            else if (type === 'pilotCharacterCertFile') {
                if (event.size / 1024 > maxSize) {
                    // eslint-disable-next-line
                    alert("File size limit 5 MB");
                }
                data[index].pilotCharacterCertFile = event;
            }
            else if (type === 'pilotMedicalExamCertFile') {
                if (event.size / 1024 > maxSize) {
                    // eslint-disable-next-line
                    alert("File size limit 5 MB");
                }
                data[index].pilotMedicalExamCertFile = event;
            }
            else if (type === 'rentalAgreementFile') {
                if (event.size / 1024 > maxSize) {
                    // eslint-disable-next-line
                    alert("File size limit 5 MB");
                }
                data[index].rentalAgreementFile = event;
            }
            else if (type === 'satBaraCertFile') {
                if (event.size / 1024 > maxSize) {
                    // eslint-disable-next-line
                    alert("File size limit 5 MB");
                }
                data[index].satBaraCertFile = event;
            }
            else if (type === 'indemnityFormFile') {
                if (event.size / 1024 > maxSize) {
                    // eslint-disable-next-line
                    alert("File size limit 5 MB");
                }
                data[index].indemnityFormFile = event;
            }
        }
        setEquipments(data);
    }

    const addFields = () => {
        const newfield = { adventureactivity: { label: "", value: "" }, equipname: '', unit: '', manufacturer: '', purchasedate: new Date() }
        setEquipments([...equipments, newfield])
    }

    const removeFields = (index) => {
        const data = [...equipments];
        data.splice(index, 1)
        setEquipments(data)
    }

    const backHandler = () => {
        navigate('/authentication/sign-in/basic');
    };


    function AddEquipmentDocument(adventureId, ele) {
        const errs = {};
        setLoading(true);
        const formData = new FormData();
        formData.append("AdventureRegistrationId", adventureId);
        formData.append("AdventureActivity", ele.adventureactivity.value);

        if (activityCategory.value === 'Water Activities') {
            if (ele.adventureactivity.value === 'Banana Boat' || ele.adventureactivity.value === 'Bumper Boat'
                || ele.adventureactivity.value === 'Sofa Boat' || ele.adventureactivity.value === 'Pedal Boat') {
                formData.append("BoatPhoto", ele.boatPhotoFile);
                formData.append("FitnessCertificate", ele.fitnessCertFile);
            }
            else if (ele.adventureactivity.value === 'Jet Ski' || ele.adventureactivity.value === 'Parasailing' || ele.adventureactivity.value === 'Speed Boat'
                || ele.adventureactivity.value === 'River Cruz' || ele.adventureactivity.value === 'House Boat') {
                formData.append("SurveyCertificate", ele.surveyCerificateFile);
                formData.append("VRCCertificate", ele.vrcCerificateFile);
                formData.append("BoatInsurance", ele.boatInsuranceFile);
                formData.append("BoatPhoto", ele.boatPhotoFile);
                formData.append("PowerBoatHandlingCertificate", ele.powerBoatHandlingCertFile);
                formData.append("LifeSavingCertificate", ele.lifeSavingCertFile);
                formData.append("ThirdPartyInsuranceFile", ele.thirdPartyInsuranceFile);
            }
            else {
                formData.append("PANForNonMandatoryDocuments", panFile);
            }
        }
        if (activityCategory.value === 'Air Activities') {
            formData.append("PilotLicense", ele.paraglidingSkilledFile);
            formData.append("TouristInsurance", ele.pilotInsuranceCertFile);
            formData.append("PilotCharacterCertificate", ele.pilotCharacterCertFile);
            formData.append("PilotMedicalExamCertificate", ele.pilotMedicalExamCertFile);
            formData.append("RentalAgreement", ele.rentalAgreementFile);
            formData.append("7-12GramPanchayatNOC", ele.satBaraCertFile);
            formData.append("IndemnityForm", ele.indemnityFormFile);
            formData.append("PANForNonMandatoryDocuments", panFile);
        }
        if (activityCategory.value === 'Land Activities') {
            if (ele.adventureactivity.value === 'All Terrian Vehicle (ATV)') {
                formData.append("ATVPhotos", ele.atvFile);
                formData.append("ATVPurchaseReceipt", ele.atvPurchaseFile);
                formData.append("License", ele.licenceFile);
            }
            else if (ele.adventureactivity.value !== 'All Terrian Vehicle (ATV)' && applicantType.value !== 'Person') {
                if (applicantType.value === 'Sole Proprietorship') {
                    formData.append("ShopAct", ele.companyRegistrationCertFile);
                }
                else if (applicantType.value === 'Company') {
                    formData.append("CompanyRegistrationCertificate", ele.companyRegistrationCertFile);
                }
                else if (applicantType.value === 'Partnership Business') {
                    formData.append("PartnershipDeed", ele.companyRegistrationCertFile);
                }
                else if (applicantType.value === 'LLP (Limited Liability Partnership)') {
                    formData.append("LLPRegistrationCertificate", ele.companyRegistrationCertFile);
                }
                else if (applicantType.value === 'Trust') {
                    formData.append("TrustRegistrationCertificate", ele.companyRegistrationCertFile);
                }
                else if (applicantType.value === 'Co-operative Society') {
                    formData.append("CooperativeSocietyRegistrationCertificate", ele.companyRegistrationCertFile);
                }

                formData.append("SpeciallySkilledCertificate", ele.skilledCertFile);
                formData.append("TouristInsuranceCertificate", ele.insuranceCertFile);
                formData.append("PhysicalFitnessCertificate", ele.physicalFitCertFile);
                formData.append("CharacterCertificate", ele.landCharacterCertFile);
            }
            else if (ele.adventureactivity.value !== 'All Terrian Vehicle (ATV)' && applicantType.value === 'Person') {
                formData.append("PANForNonMandatoryDocuments", panFile);
            }
        }

        fetch(
            service.ADD_EQUIPMENT_DOCUMENTS,
            {
                method: 'POST',
                body: formData
            })
            .then(res => res.json())
            .then(result => {
                if (result.messageType === 'Failed') {
                    // eslint-disable-next-line
                    alert(result.message);
                }
                else {
                    errs[".invalid"] = result.message.message;
                    setErrors(errs);
                }
            });
        setLoading(false);
    }

    function AddEquipment(adventureId) {
        let flag = true;
        const errs = {};
        setLoading(true);
        equipments.forEach(element => {
            if (element.equipname && element.manufacturer && element.adventureactivity.value !== '') {
                fetch(
                    service.ADD_ADVENTURE_TOUR_EQUIPMENT,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            AdventureRegistrationId: adventureId,
                            Equipement: element.equipname,
                            NoOfUnits: element.unit,
                            ManufacturerName: element.manufacturer,
                            PurchaseDate: moment(element.purchasedate).format("MM/DD/YYYY")
                        })
                    })
                    .then(res => res.json())
                    .then(result => {
                        if (result.messageType === 'Success') {
                            AddEquipmentDocument(adventureId, element)
                        }
                        else if (result.messageType === 'Failed') {
                            flag = false;
                            // eslint-disable-next-line
                            alert(result.message);
                        }
                        else {
                            flag = false;
                            errs[".invalid"] = result.message.message;
                            setErrors(errs);
                        }
                    });
            }
        });
        if (flag) {
            // eslint-disable-next-line
            alert("Congratulations! Your application has been submitted successfully.");
            navigate('/adventuretourregistrationsuccess');
        }
        setLoading(false);
    }

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            let status = "Registration";
            if (JSON.parse(window.sessionStorage.getItem('userData'))) {
                status = "Renewal";
            }
            const formData = new FormData();
            formData.append("AdventureRegistrationId", 0);
            formData.append("EmailId", state.emailid);
            formData.append("MobileNo", state.mobileno);
            formData.append("ApplicantName", state.applicantname);
            formData.append("Name", state.name);
            formData.append("ApplicantType", applicantType.value);
            formData.append("Operator", operator);
            formData.append("Aggregator", aggregator);
            formData.append("Camp", camp);
            formData.append("TrainingInstitute", trainingInstitute);
            formData.append("Association", association);
            formData.append("IndividualOrganizer", indivisualOraganizer);
            formData.append("AuthorizedPerson", state.authorizedperson);
            formData.append("BusinessStartDate", (new Date(startDate)).toUTCString());
            formData.append("ApplicantAddress", state.applicantaddress);
            formData.append("COAddress", state.coaddress);
            formData.append("RegionId", regionId.value);
            formData.append("DistrictId", districtId.value);
            formData.append("WhatsAppNo", state.whatsappno);
            formData.append("ApplicantWebSite", state.applicantwebsite);
            formData.append("SocialMediaHandles", state.socialmediahandle);
            formData.append("ActivityCategory", activityCategory.value);
            formData.append("Activities", '');
            formData.append("OtherActivity", state.otheractivity);
            formData.append("NaukaViharJalkrida", naukavihar ? naukavihar.value : '');
            formData.append("BeachName", state.beachname);
            formData.append("ActivityLocationAddress", state.activitylocationaddress);
            formData.append("PANFile", panFile);
            formData.append("AadharFile", adharFile);
            formData.append("AddressProofFile", addressProofFile);
            formData.append("ChallanFile", challanFile);
            formData.append("OrganizationProofFile", organizationProofFile);
            formData.append("IncomeTaxFile", incomeTaxFile);
            formData.append("GSTCertificateFile", gstCertificateFile);
            formData.append("DeclarationFile", declarationFile);
            formData.append("RenewalStatus", status);
            // formData.append("DeclarationForTrainersFile", declarationForTrainersFile);

            if (activityCategory.value === 'Water Activities') {
                formData.append("BoatRegistrationFile", boatRegistrationFile);
                formData.append("PCCPoliceClearanceCertificate", PCCPoliceClearanceCertFile);
                formData.append("RenewalFeeChallan", renewalFeeChallanFile);
                formData.append("TrainerFirstAidProviderDeclaration", waterTrainerFirstAidDeclarationFile);
            }
            if (activityCategory.value === 'Air Activities') {
                formData.append("UDYAMRegistrationCertificate", UDYAMRegistrationCertFile);
                formData.append("FirstAidCertificate", firstAidCertFile);
                formData.append("TrainerFirstAidProviderDeclaration", airTrainerFirstAidDeclarationFile);
            }
            if (activityCategory.value === 'Land Activities') {
                formData.append("TrainerFirstAidProviderCertificate", landFirstAidCertFile);
                formData.append("TrainerFirstAidProviderDeclaration", landTrainerFirstAidDeclarationFile);
            }
            fetch(
                service.ADD_UPDATE_ADVENTURE_TOUR_REGISTRATION,
                {
                    method: 'POST',
                    body: formData
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        AddEquipment(result.id);
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    return (
        <div style={{ width: '100%' }}>
            {/* <DashboardNavbar /> */}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Adventure Tourism Registration (Application for Provisional Certificate)
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <TableContainer component={Paper} style={{ width: '80%', alignSelf: 'center' }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan="2" align="center" style={{ fontWeight: 'bold' }}>
                                                    Directorate of Tourism (Government of Maharashtra) has announced the `Adventure Tourism Initiative Policy`. Fill out the form below to register yourself or your organization temporarily.
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2" align="center" style={{ fontWeight: 'bold' }}>
                                                    Payment Gateway
                                                    <br />
                                                    To complete the payment visit the following link
                                                    <br />
                                                    <a href="https://gras.mahakosh.gov.in" rel="noreferrer" target="_blank">https://gras.mahakosh.gov.in</a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2" align="left" style={{ fontWeight: 'bold' }}>
                                                    * Required
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ width: '45%' }}>
                                                    <MDInput label="Email Id" fullWidth value={state.emailid || ''}
                                                        name="emailid"
                                                        onChange={changeHandler}
                                                        id="emailid"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".emailid"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Mobile No." fullWidth value={state.mobileno || ''}
                                                        name="mobileno"
                                                        onChange={changeHandler}
                                                        id="mobileno"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".mobileno"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Select
                                                        options={applicantTypeData}
                                                        name="applicantType"
                                                        value={applicantType}
                                                        onChange={applicantTypeChangeHandler}
                                                        placeholder="Select Applicant Type"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".applicantType"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Name of the Applicant"
                                                        fullWidth value={state.applicantname || ''}
                                                        name="applicantname"
                                                        onChange={changeHandler}
                                                        id="applicantname"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".applicantname"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Name of the Individual/Company/Partnership Firm/Trust etc. to be registered"
                                                        fullWidth value={state.name || ''}
                                                        name="name"
                                                        onChange={changeHandler}
                                                        id="name"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".name"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2" style={{ fontWeight: 'bold' }}>
                                                    Category For Applicant Registration
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Checkbox checked={operator || false} value={operator}
                                                        onChange={(e) => handleOperatorCheckboxChange(e.target.checked)}
                                                        id="chkOperator" />
                                                    Adventure tour operator
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox checked={aggregator || false} value={aggregator}
                                                        onChange={(e) => handleAggregatorCheckboxChange(e.target.checked)}
                                                        id="chkAggregator" />
                                                    Aggregator / Resellers
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Checkbox checked={camp || false} value={camp}
                                                        onChange={(e) => handleCampCheckboxChange(e.target.checked)}
                                                        id="chkCamp" />
                                                    Camp / Resorts
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox checked={trainingInstitute || false} value={trainingInstitute}
                                                        onChange={(e) => handleTrainingInstituteCheckboxChange(e.target.checked)}
                                                        id="chkTrainingInstitute" />
                                                    Training Institutes and Institutes e.g. Mountaineering Institutes, Paragliding Schools etc.
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Checkbox checked={association || false} value={association}
                                                        onChange={(e) => handleAssociationCheckboxChange(e.target.checked)}
                                                        id="chkAssociation" />
                                                    Association / Federation
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".categoryregistration"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox checked={indivisualOraganizer || false} value={indivisualOraganizer}
                                                        onChange={(e) => handleIndividualOrganizerCheckboxChange(e.target.checked)}
                                                        id="chkIndivisualOraganizer" />
                                                    Individual Organizer
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Name and Designation of the person authorized by the organization/company"
                                                        fullWidth value={state.authorizedperson || ''}
                                                        name="authorizedperson"
                                                        onChange={changeHandler}
                                                        id="authorizedperson"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".authorizedperson"]}</div>
                                                </TableCell>
                                                <TableCell style={{ width: '35%' }}>
                                                    Business Start Date
                                                    <DatePicker
                                                        id="startDate"
                                                        selected={startDate}
                                                        onChange={date => setStartDate(date)}
                                                        dateFormat="MM/dd/yyyy"
                                                        tabIndex={0}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".startDate"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Applicant Address"
                                                        fullWidth value={state.applicantaddress || ''}
                                                        name="applicantaddress"
                                                        onChange={changeHandler}
                                                        id="applicantaddress"
                                                        required
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".applicantaddress"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Correspondence Address if Different from Applicant Address"
                                                        fullWidth value={state.coaddress || ''}
                                                        name="coaddress"
                                                        onChange={changeHandler}
                                                        id="coaddress"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".coaddress"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Select
                                                        options={regionData}
                                                        name="region"
                                                        value={regionId}
                                                        onChange={regionChangeHandler}
                                                        placeholder="Select Region"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".region"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Select className="basic-single"
                                                        options={districtData}
                                                        name="district"
                                                        value={districtId}
                                                        onChange={districtChangeHandler}
                                                        isSearchable
                                                        placeholder="Select District"
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".district"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Whats App Number"
                                                        fullWidth value={state.whatsappno || ''}
                                                        name="whatsappno"
                                                        onChange={changeHandler}
                                                        id="whatsappno"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".whatsappno"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Applicant Website"
                                                        fullWidth value={state.applicantwebsite || ''}
                                                        name="applicantwebsite"
                                                        onChange={changeHandler}
                                                        id="applicantwebsite"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".applicantwebsite"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Applicant Social Media Handles"
                                                        fullWidth value={state.socialmediahandle || ''}
                                                        name="socialmediahandle"
                                                        onChange={changeHandler}
                                                        id="socialmediahandle"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".socialmediahandle"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    Adventure Activity Category
                                                    <Select
                                                        options={activityCategoryData}
                                                        name="activityCategory"
                                                        value={activityCategory}
                                                        onChange={activityCategoryChangeHandler}
                                                        placeholder="Select"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".activityCategory"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            {activityCategory.value === 'Water Activities' ?
                                                <TableRow>
                                                    <TableCell style={{ fontWeight: 'bold' }}>
                                                        Water Activity Type
                                                        <Select
                                                            options={naukaData}
                                                            name="naukavihar"
                                                            value={naukavihar}
                                                            onChange={naukaChangeHandler}
                                                            placeholder="Select"
                                                            isSearchable
                                                            styles={selectStyles}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".naukavihar"]}</div>
                                                    </TableCell>
                                                </TableRow>
                                                : null}
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Activity Location Address"
                                                        fullWidth value={state.activitylocationaddress || ''}
                                                        name="activitylocationaddress"
                                                        onChange={changeHandler}
                                                        id="activitylocationaddress"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'green' }}>Applicant can add multiple addresses by numbering them as 1, 2, 3 ...</div>
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".activitylocationaddress"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label={activityCategory.value === 'Land Activities' ? "Beach/Location Name" : "Beach Name"}
                                                        fullWidth value={state.beachname || ''}
                                                        name="beachname"
                                                        onChange={changeHandler}
                                                        id="beachname"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".beachname"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <MDInput label="Specify your activity if not listed above"
                                                        fullWidth value={state.otheractivity || ''}
                                                        name="otheractivity"
                                                        onChange={changeHandler}
                                                        id="otheractivity"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".otheractivity"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            {activityCategory.value === 'Water Activities' ?
                                                <TableRow>
                                                    <TableCell colSpan="2">
                                                        <Table>
                                                            <TableRow>
                                                                <TableCell width="25%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                    Adventure Activities
                                                                </TableCell>
                                                                <TableCell width="30%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                    Equipment Name And Number
                                                                </TableCell>
                                                                <TableCell width="10%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                    Name Of Manufacturer
                                                                </TableCell>
                                                                <TableCell width="5%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                    Validity Date
                                                                </TableCell>
                                                                <TableCell width="25%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                    Documents (File Size 5 MB)
                                                                </TableCell>
                                                                <TableCell width="5%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                    Remove
                                                                </TableCell>
                                                            </TableRow>
                                                            {equipments.map((input, index) => (
                                                                <TableRow
                                                                    key={input.name}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell>
                                                                        <Select
                                                                            options={activityData}
                                                                            name="activity"
                                                                            value={input.adventureactivity}
                                                                            onChange={event => handleFormChange(index, event, 'activy')}
                                                                            placeholder="Select"
                                                                            isSearchable
                                                                            styles={selectStyles}
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.activity${index}`]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Equipment Name"
                                                                            fullWidth value={input.equipname}
                                                                            name="equipname"
                                                                            onChange={event => handleFormChange(index, event, 'equipname')}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Manufacturer Name"
                                                                            fullWidth value={input.manufacturer}
                                                                            name="manufacturer"
                                                                            onChange={event => handleFormChange(index, event, 'manufacturer')}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <DatePicker
                                                                            name="purchasedate"
                                                                            selected={input.purchasedate}
                                                                            onChange={date => handleFormChange(index, date, 'date')}
                                                                            dateFormat="MM/dd/yyyy"
                                                                        />
                                                                    </TableCell>
                                                                    {input.adventureactivity.value === 'Banana Boat' || input.adventureactivity.value === 'Bumper Boat'
                                                                        || input.adventureactivity.value === 'Sofa Boat' || input.adventureactivity.value === 'Pedal Boat' ?
                                                                        <TableCell>
                                                                            <Table>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontSize: '14px' }}>
                                                                                        <span style={{ color: 'red' }}>*</span> Fitness Certificate
                                                                                        <br />
                                                                                        <TextField
                                                                                            id="fitnessCertFile"
                                                                                            type="file"
                                                                                            onChange={ev => handleFormChange(index, ev.target.files[0], 'fitnessCertFile')}
                                                                                            style={{ width: 200 }}
                                                                                            required
                                                                                        />
                                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.fitnessCertFile${index}`]}</div>
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontSize: '14px' }}>
                                                                                        <span style={{ color: 'red' }}>*</span> Boat Photo
                                                                                        <br />
                                                                                        <TextField
                                                                                            id="boatPhotoFile"
                                                                                            type="file"
                                                                                            onChange={ev => handleFormChange(index, ev.target.files[0], 'boatPhotoFile')}
                                                                                            style={{ width: 200 }}
                                                                                            required
                                                                                        />
                                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.boatPhotoFile${index}`]}</div>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </Table>
                                                                        </TableCell>
                                                                        : null}
                                                                    {input.adventureactivity.value === 'Jet Ski' || input.adventureactivity.value === 'Parasailing' || input.adventureactivity.value === 'Speed Boat'
                                                                        || input.adventureactivity.value === 'River Cruz' || input.adventureactivity.value === 'House Boat' ?
                                                                        <TableCell>
                                                                            <Table>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontSize: '14px' }}>
                                                                                        <span style={{ color: 'red' }}>*</span> Survey Certificate
                                                                                        <br />
                                                                                        <TextField
                                                                                            id="surveyCerificateFile"
                                                                                            type="file"
                                                                                            onChange={ev => handleFormChange(index, ev.target.files[0], 'surveyCerificateFile')}
                                                                                            style={{ width: 200 }}
                                                                                            required
                                                                                        />
                                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.surveyCerificateFile${index}`]}</div>
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontSize: '14px' }}>
                                                                                        <span style={{ color: 'red' }}>*</span> V.R.C. Certificate
                                                                                        <br />
                                                                                        <TextField
                                                                                            id="vrcCerificateFile"
                                                                                            type="file"
                                                                                            onChange={ev => handleFormChange(index, ev.target.files[0], 'vrcCerificateFile')}
                                                                                            style={{ width: 200 }}
                                                                                            required
                                                                                        />
                                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.vrcCerificateFile${index}`]}</div>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontSize: '14px' }}>
                                                                                        <span style={{ color: 'red' }}>*</span> Boat Insurance
                                                                                        <br />
                                                                                        <TextField
                                                                                            id="boatInsuranceFile"
                                                                                            type="file"
                                                                                            onChange={ev => handleFormChange(index, ev.target.files[0], 'boatInsuranceFile')}
                                                                                            style={{ width: 200 }}
                                                                                            required
                                                                                        />
                                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.boatInsuranceFile${index}`]}</div>
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontSize: '14px' }}>
                                                                                        <span style={{ color: 'red' }}>*</span> Boat Photo
                                                                                        <br />
                                                                                        <TextField
                                                                                            id="boatPhotoFile"
                                                                                            type="file"
                                                                                            onChange={ev => handleFormChange(index, ev.target.files[0], 'boatPhotoFile')}
                                                                                            style={{ width: 200 }}
                                                                                            required
                                                                                        />
                                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.boatPhotoFile${index}`]}</div>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontSize: '14px' }}>
                                                                                        <span style={{ color: 'red' }}>*</span> Power Boat Handling Certificate File
                                                                                        <br />
                                                                                        <TextField
                                                                                            id="powerBoatHandlingCertFile"
                                                                                            type="file"
                                                                                            onChange={ev => handleFormChange(index, ev.target.files[0], 'powerBoatHandlingCertFile')}
                                                                                            style={{ width: 200 }}
                                                                                            required
                                                                                        />
                                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.powerBoatHandlingCertFile${index}`]}</div>
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontSize: '14px' }}>
                                                                                        <span style={{ color: 'red' }}>*</span> Life Saving Certificate File
                                                                                        <br />
                                                                                        <TextField
                                                                                            id="lifeSavingCertFile"
                                                                                            type="file"
                                                                                            onChange={ev => handleFormChange(index, ev.target.files[0], 'lifeSavingCertFile')}
                                                                                            style={{ width: 200 }}
                                                                                            required
                                                                                        />
                                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.lifeSavingCertFile${index}`]}</div>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontSize: '14px' }}>
                                                                                        <span style={{ color: 'red' }}>*</span> Third Party Insurance File
                                                                                        <br />
                                                                                        <TextField
                                                                                            id="thirdPartyInsuranceFile"
                                                                                            type="file"
                                                                                            onChange={ev => handleFormChange(index, ev.target.files[0], 'thirdPartyInsuranceFile')}
                                                                                            style={{ width: 200 }}
                                                                                            required
                                                                                        />
                                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.thirdPartyInsuranceFile${index}`]}</div>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </Table>
                                                                        </TableCell>
                                                                        : null}
                                                                    <TableCell>
                                                                        <MDButton color="secondary" onClick={() => removeFields(index)}>
                                                                            Remove
                                                                        </MDButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                            <TableRow>
                                                                <TableCell>
                                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".equipment"]}</div>
                                                                    <MDButton color="secondary" onClick={addFields}>
                                                                        Add More
                                                                    </MDButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow>
                                                : null
                                            }
                                            {activityCategory.value === 'Land Activities' ?
                                                <TableRow>
                                                    <TableCell colSpan="2">
                                                        <Table>
                                                            <TableRow>
                                                                <TableCell width="30%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                    Adventure Activities
                                                                </TableCell>
                                                                <TableCell width="30%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                    Name Of Equipments
                                                                </TableCell>
                                                                <TableCell width="10%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                    Name Of Manufacturer
                                                                </TableCell>
                                                                <TableCell width="5%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                    Validity Date
                                                                </TableCell>
                                                                <TableCell width="20%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                    Documents (File Size 5 MB)
                                                                </TableCell>
                                                                <TableCell width="5%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                    Remove
                                                                </TableCell>
                                                            </TableRow>
                                                            {equipments.map((input, index) => (
                                                                <TableRow
                                                                    key={input.name}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell>
                                                                        <Select
                                                                            options={activityData}
                                                                            name="activity"
                                                                            value={input.adventureactivity}
                                                                            onChange={event => handleFormChange(index, event, 'activy')}
                                                                            placeholder="Select"
                                                                            isSearchable
                                                                            styles={selectStyles}
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.activity${index}`]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Equipment Name"
                                                                            fullWidth value={input.equipname}
                                                                            name="equipname"
                                                                            onChange={event => handleFormChange(index, event, 'equipname')}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Manufacturer Name"
                                                                            fullWidth value={input.manufacturer}
                                                                            name="manufacturer"
                                                                            onChange={event => handleFormChange(index, event, 'manufacturer')}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <DatePicker
                                                                            name="purchasedate"
                                                                            selected={input.purchasedate}
                                                                            onChange={date => handleFormChange(index, date, 'date')}
                                                                            dateFormat="MM/dd/yyyy"
                                                                        />
                                                                    </TableCell>
                                                                    {input.adventureactivity.value === 'All Terrian Vehicle (ATV)' ?
                                                                        <TableCell>
                                                                            <Table>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontSize: '14px' }}>
                                                                                        <span style={{ color: 'red' }}>*</span> ATV Photos
                                                                                        <br />
                                                                                        <TextField
                                                                                            id="atvFile"
                                                                                            type="file"
                                                                                            onChange={ev => handleFormChange(index, ev.target.files[0], 'atvFile')}
                                                                                            style={{ width: 200 }}
                                                                                            required
                                                                                        />
                                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.atvFile${index}`]}</div>
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontSize: '14px' }}>
                                                                                        <span style={{ color: 'red' }}>*</span> ATV Purchase Receipt
                                                                                        <br />
                                                                                        <TextField
                                                                                            id="atvPurchaseFile"
                                                                                            type="file"
                                                                                            onChange={ev => handleFormChange(index, ev.target.files[0], 'atvPurchaseFile')}
                                                                                            style={{ width: 200 }}
                                                                                            required
                                                                                        />
                                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.atvPurchaseFile${index}`]}</div>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontSize: '14px' }}>
                                                                                        <span style={{ color: 'red' }}>*</span> License (2/4 Wheeler)
                                                                                        <br />
                                                                                        <TextField
                                                                                            id="licenceFile"
                                                                                            type="file"
                                                                                            onChange={ev => handleFormChange(index, ev.target.files[0], 'licenceFile')}
                                                                                            style={{ width: 200 }}
                                                                                            required
                                                                                        />
                                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.licenceFile${index}`]}</div>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </Table>
                                                                        </TableCell>
                                                                        : null}
                                                                    {input.adventureactivity.value !== 'All Terrian Vehicle (ATV)' && applicantType.value !== 'Person' ?
                                                                        <TableCell>
                                                                            <Table>
                                                                                <TableRow>
                                                                                    {applicantType && applicantType.value === 'Sole Proprietorship' ?
                                                                                        <TableCell style={{ fontSize: '14px' }}>
                                                                                            <span style={{ color: 'red' }}>*</span>Shop Act
                                                                                            <br />
                                                                                            <TextField
                                                                                                id="companyRegistrationCertFile"
                                                                                                type="file"
                                                                                                onChange={ev => handleFormChange(index, ev.target.files[0], 'companyRegistrationCertFile')}
                                                                                                style={{ width: 200 }}
                                                                                                required
                                                                                            />
                                                                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.companyRegistrationCertFile${index}`]}</div>
                                                                                        </TableCell>
                                                                                        : null}
                                                                                    {applicantType && applicantType.value === 'Company' ?
                                                                                        <TableCell style={{ fontSize: '14px' }}>
                                                                                            <span style={{ color: 'red' }}>*</span>Company Registration Certificate
                                                                                            <br />
                                                                                            <TextField
                                                                                                id="companyRegistrationCertFile"
                                                                                                type="file"
                                                                                                onChange={ev => handleFormChange(index, ev.target.files[0], 'companyRegistrationCertFile')}
                                                                                                style={{ width: 200 }}
                                                                                                required
                                                                                            />
                                                                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.companyRegistrationCertFile${index}`]}</div>
                                                                                        </TableCell>
                                                                                        : null}
                                                                                    {applicantType && applicantType.value === 'Partnership Business' ?
                                                                                        <TableCell style={{ fontSize: '14px' }}>
                                                                                            <span style={{ color: 'red' }}>*</span>Partnership Deed
                                                                                            <br />
                                                                                            <TextField
                                                                                                id="companyRegistrationCertFile"
                                                                                                type="file"
                                                                                                onChange={ev => handleFormChange(index, ev.target.files[0], 'companyRegistrationCertFile')}
                                                                                                style={{ width: 200 }}
                                                                                                required
                                                                                            />
                                                                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.companyRegistrationCertFile${index}`]}</div>
                                                                                        </TableCell>
                                                                                        : null}
                                                                                    {applicantType && applicantType.value === 'LLP (Limited Liability Partnership)' ?
                                                                                        <TableCell style={{ fontSize: '14px' }}>
                                                                                            <span style={{ color: 'red' }}>*</span>LLP Registration Certificate
                                                                                            <br />
                                                                                            <TextField
                                                                                                id="companyRegistrationCertFile"
                                                                                                type="file"
                                                                                                onChange={ev => handleFormChange(index, ev.target.files[0], 'companyRegistrationCertFile')}
                                                                                                style={{ width: 200 }}
                                                                                                required
                                                                                            />
                                                                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.companyRegistrationCertFile${index}`]}</div>
                                                                                        </TableCell>
                                                                                        : null}
                                                                                    {applicantType && applicantType.value === 'Trust' ?
                                                                                        <TableCell style={{ fontSize: '14px' }}>
                                                                                            <span style={{ color: 'red' }}>*</span>Trust Registration Certificate
                                                                                            <br />
                                                                                            <TextField
                                                                                                id="companyRegistrationCertFile"
                                                                                                type="file"
                                                                                                onChange={ev => handleFormChange(index, ev.target.files[0], 'companyRegistrationCertFile')}
                                                                                                style={{ width: 200 }}
                                                                                                required
                                                                                            />
                                                                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.companyRegistrationCertFile${index}`]}</div>
                                                                                        </TableCell>
                                                                                        : null}
                                                                                    {applicantType && applicantType.value === 'Co-operative Society' ?
                                                                                        <TableCell style={{ fontSize: '14px' }}>
                                                                                            <span style={{ color: 'red' }}>*</span>Cooperative Society Registration Certificate
                                                                                            <br />
                                                                                            <TextField
                                                                                                id="companyRegistrationCertFile"
                                                                                                type="file"
                                                                                                onChange={ev => handleFormChange(index, ev.target.files[0], 'companyRegistrationCertFile')}
                                                                                                style={{ width: 200 }}
                                                                                                required
                                                                                            />
                                                                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.companyRegistrationCertFile${index}`]}</div>
                                                                                        </TableCell>
                                                                                        : null}
                                                                                    <TableCell style={{ fontSize: '14px' }}>
                                                                                        Operator Skilled Certificate
                                                                                        <br />
                                                                                        <TextField
                                                                                            id="skilledCertFile"
                                                                                            type="file"
                                                                                            onChange={ev => handleFormChange(index, ev.target.files[0], 'skilledCertFile')}
                                                                                            style={{ width: 200 }}
                                                                                        />
                                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.skilledCertFile${index}`]}</div>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontSize: '14px' }}>
                                                                                        Tourist Insurance Certificate
                                                                                        <br />
                                                                                        <TextField
                                                                                            id="insuranceCertFile"
                                                                                            type="file"
                                                                                            onChange={ev => handleFormChange(index, ev.target.files[0], 'insuranceCertFile')}
                                                                                            style={{ width: 200 }}
                                                                                        />
                                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.insuranceCertFile${index}`]}</div>
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontSize: '14px' }}>
                                                                                        Physical Fitness Certificate
                                                                                        <br />
                                                                                        <TextField
                                                                                            id="physicalFitCertFile"
                                                                                            type="file"
                                                                                            onChange={ev => handleFormChange(index, ev.target.files[0], 'physicalFitCertFile')}
                                                                                            style={{ width: 200 }}
                                                                                        />
                                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.physicalFitCertFile${index}`]}</div>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontSize: '14px' }}>
                                                                                        Character Certificate / Police Clearance Certificate
                                                                                        <br />
                                                                                        <TextField
                                                                                            id="landCharacterCertFile"
                                                                                            type="file"
                                                                                            onChange={ev => handleFormChange(index, ev.target.files[0], 'landCharacterCertFile')}
                                                                                            style={{ width: 200 }}
                                                                                            required
                                                                                        />
                                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.landCharacterCertFile${index}`]}</div>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </Table>
                                                                        </TableCell>
                                                                        : null}
                                                                    <TableCell>
                                                                        <MDButton color="secondary" onClick={() => removeFields(index)}>
                                                                            Remove
                                                                        </MDButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                            <TableRow>
                                                                <TableCell>
                                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".equipment"]}</div>
                                                                    <MDButton color="secondary" onClick={addFields}>
                                                                        Add More
                                                                    </MDButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow> : null}
                                            {activityCategory.value === 'Air Activities' ?
                                                <TableRow>
                                                    <TableCell colSpan="2">
                                                        <Table>
                                                            <TableRow>
                                                                <TableCell width="25%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                    Adventure Activities
                                                                </TableCell>
                                                                <TableCell width="30%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                    Name Of Equipments
                                                                </TableCell>
                                                                <TableCell width="10%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                    Name Of Manufacturer
                                                                </TableCell>
                                                                <TableCell width="5%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                    Validity Date
                                                                </TableCell>
                                                                <TableCell width="25%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                    Documents (File Size 5 MB)
                                                                </TableCell>
                                                                <TableCell width="5%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                    Remove
                                                                </TableCell>
                                                            </TableRow>
                                                            {equipments.map((input, index) => (
                                                                <TableRow
                                                                    key={input.name}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell>
                                                                        <Select
                                                                            options={activityData}
                                                                            name="activity"
                                                                            value={input.adventureactivity}
                                                                            onChange={event => handleFormChange(index, event, 'activy')}
                                                                            placeholder="Select"
                                                                            isSearchable
                                                                            styles={selectStyles}
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.activity${index}`]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Equipment Name"
                                                                            fullWidth value={input.equipname}
                                                                            name="equipname"
                                                                            onChange={event => handleFormChange(index, event, 'equipname')}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Manufacturer Name"
                                                                            fullWidth value={input.manufacturer}
                                                                            name="manufacturer"
                                                                            onChange={event => handleFormChange(index, event, 'manufacturer')}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <DatePicker
                                                                            name="purchasedate"
                                                                            selected={input.purchasedate}
                                                                            onChange={date => handleFormChange(index, date, 'date')}
                                                                            dateFormat="MM/dd/yyyy"
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Table>
                                                                            <TableRow>
                                                                                <TableCell style={{ fontSize: '14px' }}>
                                                                                    Pilot License
                                                                                    <br />
                                                                                    <TextField
                                                                                        id="paraglidingSkilledFile"
                                                                                        type="file"
                                                                                        onChange={ev => handleFormChange(index, ev.target.files[0], 'paraglidingSkilledFile')}
                                                                                        style={{ width: 200 }}
                                                                                    />
                                                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.paraglidingSkilledFile${index}`]}</div>
                                                                                </TableCell>
                                                                                <TableCell style={{ fontSize: '14px' }}>
                                                                                    Tourist Insurance
                                                                                    <br />
                                                                                    <TextField
                                                                                        id="pilotInsuranceCertFile"
                                                                                        type="file"
                                                                                        onChange={ev => handleFormChange(index, ev.target.files[0], 'pilotInsuranceCertFile')}
                                                                                        style={{ width: 200 }}
                                                                                    />
                                                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.pilotInsuranceCertFile${index}`]}</div>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow>
                                                                                <TableCell style={{ fontSize: '14px' }}>
                                                                                    Pilot Character Certificate / Police Clearance Certificate
                                                                                    <br />
                                                                                    <TextField
                                                                                        id="pilotCharacterCertFile"
                                                                                        type="file"
                                                                                        onChange={ev => handleFormChange(index, ev.target.files[0], 'pilotCharacterCertFile')}
                                                                                        style={{ width: 200 }}
                                                                                    />
                                                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.pilotCharacterCertFile${index}`]}</div>
                                                                                </TableCell>
                                                                                <TableCell style={{ fontSize: '14px' }}>
                                                                                    Pilot Medical Examination Certificate/Physical Fitness Certificate
                                                                                    <br />
                                                                                    <TextField
                                                                                        id="pilotMedicalExamCertFile"
                                                                                        type="file"
                                                                                        onChange={ev => handleFormChange(index, ev.target.files[0], 'pilotMedicalExamCertFile')}
                                                                                        style={{ width: 200 }}
                                                                                    />
                                                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.pilotMedicalExamCertFile${index}`]}</div>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow>
                                                                                <TableCell style={{ fontSize: '14px' }}>
                                                                                    Adventure Tourism Central is the Center Operators themselves is there a rental agreement if not Ownership
                                                                                    <br />
                                                                                    <TextField
                                                                                        id="rentalAgreementFile"
                                                                                        type="file"
                                                                                        onChange={ev => handleFormChange(index, ev.target.files[0], 'rentalAgreementFile')}
                                                                                        style={{ width: 200 }}
                                                                                    />
                                                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.rentalAgreementFile${index}`]}</div>
                                                                                </TableCell>
                                                                                <TableCell style={{ fontSize: '14px' }}>
                                                                                    7/12, GramPanchayat NOC/Nagar Palika/Mahanagar Palika/Nagar Parishad/Nagar Panchayat
                                                                                    <br />
                                                                                    <TextField
                                                                                        id="satBaraCertFile"
                                                                                        type="file"
                                                                                        onChange={ev => handleFormChange(index, ev.target.files[0], 'satBaraCertFile')}
                                                                                        style={{ width: 200 }}
                                                                                    />
                                                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.satBaraCertFile${index}`]}</div>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow>
                                                                                <TableCell style={{ fontSize: '14px' }}>
                                                                                    Indemnity Form
                                                                                    <br />
                                                                                    <TextField
                                                                                        id="indemnityFormFile"
                                                                                        type="file"
                                                                                        onChange={ev => handleFormChange(index, ev.target.files[0], 'indemnityFormFile')}
                                                                                        style={{ width: 200 }}
                                                                                    />
                                                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[`.indemnityFormFile${index}`]}</div>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </Table>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDButton color="secondary" onClick={() => removeFields(index)}>
                                                                            Remove
                                                                        </MDButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                            <TableRow>
                                                                <TableCell>
                                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".equipment"]}</div>
                                                                    <MDButton color="secondary" onClick={addFields}>
                                                                        Add More
                                                                    </MDButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow> : null}
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span>PAN Card (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="panFile"
                                                        type="file"
                                                        onChange={ev => setPanFile(ev.target.files[0])}
                                                        style={{ width: 200 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".panFile"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span>Aadhar Card (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="adharFile"
                                                        type="file"
                                                        onChange={ev => setAdharFile(ev.target.files[0])}
                                                        style={{ width: 200 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".adharFile"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span>Proof of Address (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="addressProofFile"
                                                        type="file"
                                                        onChange={ev => setAddressProofFile(ev.target.files[0])}
                                                        style={{ width: 200 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".addressProofFile"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span>Registration Fee Challan (For Rs. 500/-) (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="challanFile"
                                                        type="file"
                                                        onChange={ev => setChallanFile(ev.target.files[0])}
                                                        style={{ width: 200 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".challanFile"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    Proof of Organization / Business Registration (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="organizationProofFile"
                                                        type="file"
                                                        onChange={ev => setOrganizationProofFile(ev.target.files[0])}
                                                        style={{ width: 200 }}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".organizationProofFile"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    Copy of last 2 years income tax retunrs (IT Returns) (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="incomeTaxFile"
                                                        type="file"
                                                        onChange={ev => setIncomeTaxFile(ev.target.files[0])}
                                                        style={{ width: 200 }}

                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".incomeTaxFile"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    GST Certificate (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="gstCertificateFile"
                                                        type="file"
                                                        onChange={ev => setGSTCertificateFile(ev.target.files[0])}
                                                        style={{ width: 200 }}

                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gstCertificateFile"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span>Declaration Form (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="declarationFile"
                                                        type="file"
                                                        onChange={ev => setDeclarationFile(ev.target.files[0])}
                                                        style={{ width: 200 }}
                                                        required
                                                    />&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <a href={AdventureTourDeclaration} download="Declaration.pdf"> Download Format </a>
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".declarationFile"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            {activityCategory.value === 'Air Activities' ?
                                                <TableRow>
                                                    <TableCell colSpan="2">
                                                        <Table>
                                                            <TableRow>
                                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                                    First Aid to the Injured Conducted Training Certificate (File Size 5 MB)
                                                                    <br />
                                                                    <TextField
                                                                        id="firstAidCertFile"
                                                                        type="file"
                                                                        onChange={ev => setFirstAidCertFile(ev.target.files[0])}
                                                                        style={{ width: 200 }}
                                                                    />
                                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".firstAidCertFile"]}</div>
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                                    UDYAM Registration Certificate (File Size 5 MB)
                                                                    <br />
                                                                    <TextField
                                                                        id="UDYAMRegistrationCertFile"
                                                                        type="file"
                                                                        onChange={ev => setUDYAMRegistrationCertFile(ev.target.files[0])}
                                                                        style={{ width: 200 }}
                                                                    />
                                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".UDYAMRegistrationCertFile"]}</div>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                                    <span style={{ color: 'red' }}>*</span> Declaration Form For Trainers First Aid Providers (File Size 5 MB)
                                                                    <br />
                                                                    <TextField
                                                                        id="airTrainerFirstAidDeclarationFile"
                                                                        type="file"
                                                                        onChange={ev => setAirTrainerFirstAidDeclarationFile(ev.target.files[0])}
                                                                        style={{ width: 200 }}
                                                                    />&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    <a href={TrainerDeclaration} download="Declaration.pdf"> Download Here </a>
                                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".airTrainerFirstAidDeclarationFile"]}</div>
                                                                </TableCell>
                                                            </TableRow>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow>
                                                : null
                                            }
                                            {activityCategory.value === 'Land Activities' ?
                                                <TableRow>
                                                    <TableCell style={{ fontWeight: 'bold' }}>
                                                        Trainer First Aid Provider Certificate (File Size 5 MB)
                                                        <br />
                                                        <TextField
                                                            id="landFirstAidCertFile"
                                                            type="file"
                                                            onChange={ev => setLandFirstAidCertFile(ev.target.files[0])}
                                                            style={{ width: 200 }}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".landFirstAidCertFile"]}</div>
                                                    </TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>
                                                        Declaration Form For Trainers First Aid Providers (File Size 5 MB)
                                                        <br />
                                                        <TextField
                                                            id="landTrainerFirstAidDeclarationFile"
                                                            type="file"
                                                            onChange={ev => setLandTrainerFirstAidDeclarationFile(ev.target.files[0])}
                                                            style={{ width: 200 }}
                                                        />&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <a href={TrainerDeclaration} download="Declaration.pdf"> Download Here </a>
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".landTrainerFirstAidDeclarationFile"]}</div>
                                                    </TableCell>
                                                </TableRow> : null}
                                            {activityCategory.value === 'Water Activities' ?
                                                <TableRow>
                                                    <TableCell colSpan="2">
                                                        <Table>
                                                            <TableRow>
                                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                                    <span style={{ color: 'red' }}>*</span> Boat Registration File (File Size 5 MB)
                                                                    <br />
                                                                    <TextField
                                                                        id="boatRegistrationFile"
                                                                        type="file"
                                                                        onChange={ev => setBoatRegistrationFile(ev.target.files[0])}
                                                                        style={{ width: 200 }}
                                                                        required
                                                                    />
                                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".boatRegistrationFile"]}</div>
                                                                </TableCell>
                                                                {/* <TableCell style={{ fontWeight: 'bold' }}>
                                                                    <span style={{ color: 'red' }}>*</span>Third Party Insurance File (File Size 5 MB)
                                                                    <br />
                                                                    <TextField
                                                                        id="thirdPartyInsuranceFile"
                                                                        type="file"
                                                                        onChange={ev => setThirdPartyInsuranceFile(ev.target.files[0])}
                                                                        style={{ width: 200 }}
                                                                        required
                                                                    />
                                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".thirdPartyInsuranceFile"]}</div>
                                                                </TableCell> */}
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                                    <span style={{ color: 'red' }}>*</span> PCC Police Clearance Certificate (File Size 5 MB)
                                                                    <br />
                                                                    <TextField
                                                                        id="PCCPoliceClearanceCertFile"
                                                                        type="file"
                                                                        onChange={ev => setPCCPoliceClearanceCertFile(ev.target.files[0])}
                                                                        style={{ width: 200 }}
                                                                        required
                                                                    />
                                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".PCCPoliceClearanceCertFile"]}</div>
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                                    <span style={{ color: 'red' }}>*</span> Annual Fee Challan Copy (File Size 5 MB)
                                                                    <br />
                                                                    <TextField
                                                                        id="renewalFeeChallanFile"
                                                                        type="file"
                                                                        onChange={ev => setRenewalFeeChallanFile(ev.target.files[0])}
                                                                        style={{ width: 200 }}
                                                                        required
                                                                    />
                                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".renewalFeeChallanFile"]}</div>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                                    <span style={{ color: 'red' }}>*</span>Declaration Form For Trainers First Aid Providers (File Size 5 MB)
                                                                    <br />
                                                                    <TextField
                                                                        id="waterTrainerFirstAidDeclarationFile"
                                                                        type="file"
                                                                        onChange={ev => setWaterTrainerFirstAidDeclarationFile(ev.target.files[0])}
                                                                        style={{ width: 200 }}
                                                                    />&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    <a href={TrainerDeclaration} download="Declaration.pdf"> Download Here </a>
                                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".waterTrainerFirstAidDeclarationFile"]}</div>
                                                                </TableCell>
                                                            </TableRow>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow> : null
                                            }

                                            {/* <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    Download Declaration Form
                                                    <a href={AdventureTourDeclaration} download="Declaration.pdf"> Download Here </a>
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    Download Declaration Form For Trainers and First Aid Providers
                                                    <a href={TrainerDeclaration} download="Declaration.pdf"> Download Here </a>
                                                </TableCell>
                                            </TableRow> */}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Table style={{ width: '20%' }}>
                                    <TableRow>
                                        <TableCell>
                                            <MDBox mt={4} mb={1} textAlign="center">
                                                {loading ? <Oval
                                                    heigth="20"
                                                    width="20"
                                                    color='grey'
                                                    ariaLabel='loading'
                                                /> :
                                                    <MDButton color="info" onClick={submitHandler}>
                                                        SAVE
                                                    </MDButton>
                                                }
                                                {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                            </MDBox>
                                        </TableCell>
                                        <TableCell>
                                            <MDBox mt={4} mb={1} textAlign="center">
                                                <MDButton color="secondary" onClick={backHandler}>
                                                    Back
                                                </MDButton>
                                            </MDBox>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>

            <Footer />
        </div >
    );
}

export default AdventureTourRegistration;