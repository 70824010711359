// const baseURL = 'https://localhost:44389/api/';
 const baseURL = 'https://api.dotregistration.co.in/api/';

export const AGRO_LOGIN_URL = `${baseURL}Account/agroapplicantlogin`;
export const ADVENTURE_LOGIN_URL = `${baseURL}Account/adventureapplicantlogin`;
export const CARAVAN_LOGIN_URL = `${baseURL}Account/caravanapplicantlogin`;
export const ACCOMODATION_LOGIN_URL = `${baseURL}Account/accomodationapplicantlogin`;

export const AGRO_RENEW_URL = `${baseURL}Account/agroapplicantrenewal`;
export const ADVENTURE_RENEW_URL = `${baseURL}Account/adventureapplicantrenewal`;
export const CARAVAN_RENEW_URL = `${baseURL}Account/caravanapplicantrenewal`;
export const ACCOMODATION_RENEW_URL = `${baseURL}Account/accomodationapplicantrenewal`;

export const STATE_URL = `${baseURL}Masters/getstates`;
export const GET_REGION_BY_STATE_ID = `${baseURL}Masters/getregionssbystateid`;
export const GET_DISTRICT_BY_REGION_ID = `${baseURL}Masters/getdistrictsbyregionid`;

export const GET_AGRO_TOUR_REGISTRATION_BY_ID = `${baseURL}Applicant/getagrotourregistrationbyid`;
export const GET_AGRO_TOUR_DOCUMENT_BY_ID = `${baseURL}Applicant/getagrotourdocumentsbyid`;
export const ADD_UPDATE_AGRO_TOUR_REGISTRATION = `${baseURL}Applicant/addupdateagrotourregistration`;
export const GET_AGRO_TOUR_SITE_VISIT_DETAILS = `${baseURL}Admin/getagrotoursitevisitcriteriabyid`;
export const UPDATE_AGRO_TOUR_DOCUMENT_BY_ID = `${baseURL}Applicant/updateagrotourdocumentbyid`;

export const GET_ADVENTURE_TOUR_REGISTRATION_BY_ID = `${baseURL}Applicant/getadventuretourregistrationbyid`;
export const GET_ADVENTURE_TOUR_DOCUMENT_BY_ID = `${baseURL}Applicant/getadventuretourdocumentsbyid`;
export const GET_ADVENTURE_TOUR_EQUIPMENT_BY_ID = `${baseURL}Applicant/getadventuretourequipementsbyid`;
export const ADD_UPDATE_ADVENTURE_TOUR_REGISTRATION = `${baseURL}Applicant/addupdateadvanturetourregistration`;
export const ADD_ADVENTURE_TOUR_EQUIPMENT = `${baseURL}Applicant/addequipment`;
export const GET_ADVENTURE_TOUR_SITE_VISIT_DETAILS = `${baseURL}Admin/getadventuretoursitevisitcriteriabyid`;
export const UPDATE_ADVENTURE_TOUR_DOCUMENT_BY_ID = `${baseURL}Applicant/updateadventuretourdocumentbyid`;
export const ADD_EQUIPMENT_DOCUMENTS = `${baseURL}Applicant/addequipmentdocuments`;

export const GET_CARAVAN_REGISTRATION_BY_ID = `${baseURL}Applicant/getcaravanregistrationbyid`;
export const GET_CARAVAN_DOCUMENT_BY_ID = `${baseURL}Applicant/getcaravandocumentsbyid`;
export const ADD_UPDATE_CARAVAN_REGISTRATION = `${baseURL}Applicant/addupdatecaravanregistration`;
export const GET_CARAVAN_SITE_VISIT_DETAILS = `${baseURL}Admin/getcaravansitevisitcriteriabyid`;
export const UPDATE_CARAVAN_DOCUMENT_BY_ID = `${baseURL}Applicant/updatecaravandocumentbyid`;

export const GET_ACCOMODATION_REGISTRATION_BY_ID = `${baseURL}Applicant/getaccomodationregistrationbyid`;
export const GET_ACCOMODATION_DOCUMENT_BY_ID = `${baseURL}Applicant/getaccomodationdocumentsbyid`;
export const ADD_UPDATE_ACCOMODATION_REGISTRATION = `${baseURL}Applicant/addupdateaccomodationregistration`;
export const GET_ACCOMODATION_SITE_VISIT_DETAILS = `${baseURL}Admin/getaccomodationsitevisitcriteriabyid`;
export const UPDATE_ACCOMODATION_DOCUMENT_BY_ID = `${baseURL}Applicant/updateaccomodationdocumentbyid`;

export const GET_ACCOMODATION_CERTIFICATE_BY_ID = `${baseURL}Admin/getaccomodationcertificatebyid`;
export const GET_CARAVAN_CERTIFICATE_BY_ID = `${baseURL}Admin/getcaravancertificatebyid`;
export const GET_ADVENTURE_CERTIFICATE_BY_ID = `${baseURL}Admin/getadventurecertificatebyid`;
export const GET_AGRO_CERTIFICATE_BY_ID = `${baseURL}Admin/getagrocertificatebyid`;

export const DOWNLOAD_FILE = `${baseURL}Admin/downloadfile`;

// *************************************************************************************************

export default AGRO_LOGIN_URL;